import React from "react";

export default function CompletedCases({ header, body }) {
  return (
    <div className="completedcases">
      <h5>{header}</h5>
      <hr />
      <p>{body}</p>
    </div>
  );
}
