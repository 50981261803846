import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/sidebar.css";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
const Logo = "https://neuro-gen.s3.amazonaws.com/images/icons/logo.svg";
const DashboardIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/dashboard.svg";
const DiagnoseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/diagnose.svg";
const DoctorsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/doctor.svg";
const ExpertsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/expert.svg";
const PharmacIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/pharmacy.svg";
const ClinicalIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/clinical.svg";
const ClinicalSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/clinicalselected.svg";
const Dashboardselected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/dashboardselected.svg";
const ResearchIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/research.svg";
const ResearchSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/researchselected.svg";
const ExpertSelected =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/expertselected.svg";
const DiagnoseSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/diagnoseselected.svg";
const ImageDXicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/imagedx.svg";
const ImageDXSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/imagedxselected.svg";
const InvestigatoryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/investigation.svg";
const InvestigatorySelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/investigationselected.svg";
const SymptomsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/symptom.svg";
const SymptomsSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/symptomselected.svg";
const DoctorSelectedIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/doctorselected.svg";
const arrowdownblack =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/researcharrowblack.svg";
const UserIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/usericon.svg";
const DiamondIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diamond.svg";
const arrowicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/arrow.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";

export default function SideBar({ selected, research, isCollapsed, onToggle }) {
  const navigate = useNavigate();
  const [researchOpen, setResearchOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selected);
  const [isCollapsedTest, setIsCollapsedTest] = useState(isCollapsed);
  useEffect(() => {
    if (research) {
      setResearchOpen(true);
    } else setResearchOpen(false);
  }, [research]);
  const { data, isLoading } = useGetUserScoreQuery();

  const handleNavigation = (item) => {
    setSelectedItem(item);
    setResearchOpen(false);

    if (item === "Dashboard") {
      navigate("/home");
    }
    if (item === "Diagnose") {
      navigate("/diagnose");
    }
    if (item === "Clinical") {
      navigate("/clinical");
    }
    if (item === "Pharmac") {
      navigate("/pharmac");
    }
    if (item === "Research") {
      navigate("/research");
    }
    if (item === "Lounge") {
      navigate("/lounge");
    }
    if (item === "Expert") {
      navigate("/expert");
    }
    if (item === "Literature") {
      navigate("/research-literature");
    }
    if (item === "Article") {
      navigate("/research-article");
    }
    if (item === "Report") {
      navigate("/research-report");
    }
    if (item === "Profile") {
      navigate("/profile");
    }
    if (item === "ImageDX") {
      navigate("/imagedx");
    }
    if (item === "Investigation") {
      navigate("/investigatory");
    }
    if (item === "Symptom") {
      navigate("/symptom");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleResearchOpen = (item) => {
    if (item !== "Literature" && item !== "Article" && item !== "Report") {
      setSelectedItem("Research");
    } else {
      setSelectedItem(item);
    }
    setResearchOpen(!researchOpen);
  };

  return (
    <div
      className={`sidebar ${isCollapsed ? "sidebarcollapsed" : "sidebaropen"}`}
    >
      {!isCollapsed && (
        <div className="closesidebar" onClick={onToggle}>
          <div className="closesidebarbutton">
            <img
              src={isCollapsed ? OpenIcon : CloseIcon}
              alt="Toggle Sidebar"
            />
          </div>
        </div>
      )}
      {!isCollapsed ? (
        <>
          <div className="sidebarheader">
            <img src={Logo} alt="Logo" />
          </div>
          <hr />
        </>
      ) : (
        <>
          <div className="sidebarheader" onClick={onToggle}>
            <div className="closesidebarbutton">
              <img
                src={isCollapsed ? OpenIcon : CloseIcon}
                alt="Toggle Sidebar"
              />
            </div>
          </div>
          <hr />
        </>
      )}

      <div className="sidebarbody">
        <ul>
          <li
            className={
              selectedItem === "Dashboard"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Dashboard")}
          >
            <img
              src={
                selectedItem === "Dashboard" ? Dashboardselected : DashboardIcon
              }
              alt="Dashboard"
            />
            {!isCollapsed && <p>Dashboard</p>}
          </li>
          <li
            className={
              selectedItem === "Diagnose"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Diagnose")}
          >
            <img
              src={
                selectedItem === "Diagnose"
                  ? DiagnoseSelectedIcon
                  : DiagnoseIcon
              }
              alt="Diagnose"
            />
            {!isCollapsed && <p>Diagnose</p>}
          </li>
          <li
            className={
              selectedItem === "Clinical"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Clinical")}
          >
            <img
              src={
                selectedItem === "Clinical"
                  ? ClinicalSelectedIcon
                  : ClinicalIcon
              }
              alt="Clinical"
            />
            {!isCollapsed && <p>Clinical Case Simulation</p>}
          </li>
          <li
            className={
              selectedItem === "Research" ||
              selectedItem === "Literature" ||
              selectedItem === "Article" ||
              selectedItem === "Report"
                ? "sidebarelelementselected researchselected"
                : "sidebarelement"
            }
          >
            <div
              className={
                selectedItem === "Research" ||
                selectedItem === "Literature" ||
                selectedItem === "Article" ||
                selectedItem === "Report"
                  ? researchOpen
                    ? "researchtop"
                    : "researchtopclosed"
                  : "researchnotselected"
              }
              onClick={() => handleResearchOpen(selectedItem)}
            >
              <div className="researchtopleft">
                <img
                  src={
                    selectedItem === "Research" ||
                    selectedItem === "Literature" ||
                    selectedItem === "Article" ||
                    selectedItem === "Report"
                      ? ResearchSelectedIcon
                      : ResearchIcon
                  }
                  alt="Research"
                />
                {!isCollapsed && <p>Research</p>}
              </div>
              {selectedItem === "Research" ||
              selectedItem === "Literature" ||
              selectedItem === "Article" ||
              selectedItem === "Report" ? (
                !isCollapsed && (
                  <div className="researchtopright">
                    <img
                      src={arrowicon}
                      alt="arrow"
                      className={researchOpen ? "arrowup" : "arrowdown"}
                    />
                  </div>
                )
              ) : (
                <div className="researchtopright">
                  <img
                    src={arrowdownblack}
                    alt="Arrowdown"
                    srcset="Arrow Down"
                  />
                </div>
              )}
            </div>
            {researchOpen && !isCollapsed && (
              <div className="researchsubmenu">
                <ul>
                  <li
                    className={
                      selectedItem === "Literature" ? "submenuselected" : ""
                    }
                    onClick={() => handleNavigation("Literature")}
                  >
                    <strong>·</strong>Literature Review
                  </li>
                  <li
                    className={
                      selectedItem === "Article" ? "submenuselected" : ""
                    }
                    onClick={() => handleNavigation("Article")}
                  >
                    <strong>·</strong>Research Article/ Meta Analysis
                  </li>
                  <li
                    className={
                      selectedItem === "Report" ? "submenuselected" : ""
                    }
                    onClick={() => handleNavigation("Report")}
                  >
                    <strong>·</strong>Case Report
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li
            className={
              selectedItem === "Lounge"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Lounge")}
          >
            <img
              src={selectedItem === "Lounge" ? DoctorSelectedIcon : DoctorsIcon}
              alt="Experts"
            />
            {!isCollapsed && <p>Doctor's Lounge</p>}
          </li>
          <li
            className={
              selectedItem === "Expert"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Expert")}
          >
            <img
              src={selectedItem === "Expert" ? ExpertSelected : ExpertsIcon}
              alt="Experts"
            />
            {!isCollapsed && <p>Expert Advice</p>}
          </li>
          <li
            className={
              selectedItem === "Pharmac"
                ? "sidebarelelementselected sidebarcoming"
                : "sidebarelement sidebarcoming"
            }
          >
            <div className="sidebarcomingleft">
              <img src={PharmacIcon} alt="Pharmac" />
              {!isCollapsed && <p>PharmacEASY®</p>}
            </div>
            <div className="sidebarcomingright">
              <button>Coming Soon</button>
            </div>
          </li>
          <li
            className={
              selectedItem === "ImageDX"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("ImageDX")}
          >
            <img
              src={
                selectedItem === "ImageDX" ? ImageDXSelectedIcon : ImageDXicon
              }
              alt="Image Dx"
            />
            {!isCollapsed && <p>Image Dx</p>}
          </li>
          <li
            className={
              selectedItem === "Investigation"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Investigation")}
          >
            <img
              src={
                selectedItem === "Investigation"
                  ? InvestigatorySelectedIcon
                  : InvestigatoryIcon
              }
              alt="Investigation"
            />
            {!isCollapsed && <p>Investigatory Report Dx</p>}
          </li>
          <li
            className={
              selectedItem === "Symptom"
                ? "sidebarelelementselected"
                : "sidebarelement"
            }
            onClick={() => handleNavigation("Symptom")}
          >
            <img
              src={
                selectedItem === "Symptom" ? SymptomsSelectedIcon : SymptomsIcon
              }
              alt="Symptom"
            />
            {!isCollapsed && <p>Symptom Checker</p>}
          </li>
        </ul>
      </div>
      {!isCollapsed ? (
        <div className="sidebarfooter">
          <div className="sidebarfooterleft">
            <img
              src={UserIcon}
              alt="User"
              onClick={() => handleNavigation("Profile")}
            />
            <div className="sidebarfooterchild">
              <h6>Dr.{data?.name}</h6>
              <p>New Delhi, India </p>
            </div>
          </div>
          <div className="sidebarfooterright">
            <button>
              <img src={DiamondIcon} alt="Diamond" />
              <p>Visionary</p>
            </button>
          </div>
        </div>
      ) : (
        <div
          className="sidebarfooter"
          onClick={() => handleNavigation("Profile")}
        >
          <img src={UserIcon} alt="User" />
        </div>
      )}
    </div>
  );
}
