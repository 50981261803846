import React from "react";
const CorrectIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/correct.svg";
export default function CorrectAnswer({ explain }) {
  return (
    <div className="correctanswer">
      <img src={CorrectIcon} alt="Correct" />
      <p>{explain}</p>
    </div>
  );
}
