import React, { useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/cases.css";
import { useNavigate } from "react-router-dom";

const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const arrowright =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/arrowright.svg";
export default function CaseListing() {
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );

  return (
    <div className="homemain">
      <SideBar
        selected="Lounge"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        {" "}
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
          </div>
          <div className="profileheader">
            <p onClick={() => navigate("/home")}>Back to Dashboard </p>
            <h3>Case Listing Screen</h3>
          </div>
        </div>
        <div className="caselistingmain">
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
          <div className="caselistingelement">
            <div className="caselistingoverlay">
              <h4>Case Title</h4>
              <p>
                Pellentesque at dui vitae amet varius interdum pulvinar mi a
                lore ipsum egestas ultricies ut diam eget tempor tortor feugiat.
              </p>
              <button>
                <p>Learn More </p>
                <img src={arrowright} alt="Arrow Right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
