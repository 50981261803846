import React from "react";
const WrongIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/wrong.svg";
export default function WrongAnswer({ explain }) {
  return (
    <div className="wronganswer flex flex-col items-start justify-start">
      <div className="w-full flex justify-start flex-row gap-2">
        {" "}
        <img src={WrongIcon} alt="Correct" />
        <p>Incorrect Answer</p>
      </div>
      <p>{explain}</p>
    </div>
  );
}
