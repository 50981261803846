import { apiSlice } from "../apiSlice";

export const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkProfileComplete: builder.query({
      query: () => ({
        url: "api/v1/checkprofilecomplete/",
      }),
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (userData) => ({
        url: "api/v1/completeprofile/",
        method: "PUT",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "api/v1/auth/users/",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "api/v1/users/login/",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getProfile: builder.query({
      query: () => ({
        url: "api/v1/getprofile/",
      }),
      providesTags: ["User"],
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "api/v1/updateprofile/",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User", "Score"],
    }),
  }),
});

export const {
  useCheckProfileCompleteQuery,
  useUpdateUserMutation,
  useRegisterMutation,
  useLoginMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
} = userSlice;
