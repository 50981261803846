import React, { useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/dashboard.css";
import { useNavigate } from "react-router-dom";
import { useSolveMyQueryMutation } from "../store/General/generalSlice";
import { BiLoaderCircle } from "react-icons/bi";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import { GoRelFilePath } from "react-icons/go";
import { toast } from "react-toastify";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const UploadIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/upload.svg";
const MicIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/mic.svg";
const Diagnose =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/diagnose.svg";
const ArrowRight =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/arrowright.svg";
const ArrowupRight =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/arrowupright.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const Caseimg =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/case.png";

const SearchIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/search.svg";

const BetaIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/beta.svg";
const homebanner =
  "https://neuro-gen.s3.amazonaws.com/images/icons/homebanner.svg";

const homecollab = "https://neuro-gen.s3.amazonaws.com/images/homecollab.svg";

const worldiamge = "https://neuro-gen.s3.amazonaws.com/images/world.svg";
const ImageDXicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/imagedx.svg";

const generateStars =
  "https://neuro-gen.s3.amazonaws.com/images/icons/generatestars.svg";

const subjectsimage = "https://neuro-gen.s3.amazonaws.com/images/subjects.svg";

const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
export default function Dashboard() {
  const [addMore, setAddMore] = useState(false);
  const navigate = useNavigate();
  const [query, setquery] = useState();

  const handleSearch = async () => {
    if (!query) {
      toast.warning("please enter Query!");
      return;
    }
    const formData = new FormData();
    formData.append("query", query);
    if (file) {
      formData.append("files", file); //
    }
    const resp = await getquery(formData);
    if (resp?.error) {
      const utcDate = resp?.error?.data?.next;
      const istDate = convertUTCtoIST(utcDate);
      toast.error(`${resp?.error?.data?.error} please try after ${istDate}!`);
    } else {
      navigate(`/aisearch/${resp.data.uuid}`);
    }
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [getquery, getquerystate] = useSolveMyQueryMutation();
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { data, isLoading } = useGetUserScoreQuery();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };
  const [fileName, setFileName] = useState("Upload Here");
  const [file, setFile] = useState(null);
  return (
    <div className="homemain">
      <SideBar
        selected="Dashboard"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
            </div>
            <div className="homemainleftouter">
              <h3>Hello Dr.{data?.name},</h3>
              <p> to Elevate your Medical Journey?</p>
            </div>
          </div>
          <div className="homemainright">
            <button>
              <div className="homemainrightbuttoncontent">
                <img src={headerbadge} alt="Header Badge" />
                <b>'Novice'</b> Badge
              </div>
              <div className="headerstreaks">
                <img src={fireicon} alt="Fire" />
                <p>{data?.score || 0}</p>
              </div>
            </button>
            <button>
              <img src={reloadicon} alt="Reload" />
            </button>
          </div>
        </div>
        <div className="dashboardbody">
          <div className="dashboardheader">
            <h2>What's on your mind?</h2>
            <p>
              Example: How do Proteosome Inhibitors function in the treatment of
              Multiple Myeloma?
            </p>
            <div className="dashboardinput">
              <img src={SearchIcon} alt="Search" onClick={handleSearch} />
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => setquery(e.target.value)}
                type="text"
                placeholder="Enter your question here"
              />
              {getquerystate?.isLoading ? (
                <BiLoaderCircle className="animate-spin" />
              ) : (
                <div className="flex flex-row gap-2">
                  {/* <img src={ArrowRight} alt="Send" onClick={handleSearch} /> */}

                  <input
                    type="file"
                    id="file"
                    accept=".pdf, image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    {file && <GoRelFilePath />}
                    {!file && <img src={UploadIcon} alt="Upload" />}
                  </label>
                  <img src={BetaIcon} alt="Beta" />
                </div>
              )}
            </div>
          </div>
          <div className="dashboardgrid">
            <div
              className="dashboardgridelementone"
              onClick={() => handleNavigation("/research-literature")}
            >
              <h4>
                Become a <strong>Great Doctor,</strong> Master{" "}
                <strong>Clinical Excellence.</strong>
              </h4>
              <p>
                Step Up<strong> Your Game</strong> with Clinical Case Simulator!
              </p>
              <img
                src={homebanner}
                alt="Research"
                className="homebannerimage"
              />
            </div>
            <div className="dashboardgridelementtwo">
              <h4>
                Don't Guess - <strong>Get Accurate Diagnoses </strong>
              </h4>
              <p>
                Your<strong> Smart Symptom Checker</strong> Guide!
              </p>
              <div className="symptomchecker">
                <div className="researchelementleft">
                  <form>
                    <input type="text" placeholder="Enter Here" />
                    <input
                      type="text"
                      placeholder="ex: Lactic Acidosis"
                      required
                    />
                    <input type="text" placeholder="ex: Aphasia" />
                    {addMore ? (
                      <input type="text" placeholder="ex: Hemiparesis" />
                    ) : (
                      <button
                        className="elementselectorlightblue addmorebutton"
                        onClick={() => setAddMore(true)}
                      >
                        Add More
                      </button>
                    )}

                    <button type="submit" className="generatebutton">
                      Generate
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div
              className="dashboardgridelementthree"
              onClick={() => handleNavigation("/clinical")}
            >
              <div className="dashboardelementthreetop">
                <h4>
                  Become a <strong>Great Doctor,</strong> Master{" "}
                  <strong>Clinical Excellence.</strong>
                </h4>
                <p>
                  Step Up<strong> Your Game</strong> with Clinical Case
                  Simulator!
                </p>
              </div>
              <img src={worldiamge} alt="World" className="worldimage" />
              <div className="dashboardgridelementthreecenter">
                <img
                  src={homecollab}
                  alt="Research"
                  className="homecollabimage"
                />
              </div>
            </div>
          </div>
          <div className="dashboardgridtwo">
            <div className="dashboardgridtwoleft">
              <div className="dashboardgridheader">
                <div className="dashboardgridheaderleft">
                  <div className="dashboardheaderrow">
                    <img src={ImageDXicon} alt="Dx" />
                    <h4>
                      <strong>Spot it Early, Act Fast</strong>
                    </h4>
                  </div>
                  <p>
                    <strong>
                      Explore the Strength of Image & Investigatory Based Dx!
                    </strong>
                  </p>
                </div>
                <div className="dashboardgridheaderright">
                  <img src={BetaIcon} alt="Beta" />
                </div>
              </div>
              <div className="dashboardleftbody">
                <div className="ddxupload">
                  <div className="profilepictureupload">
                    <label htmlFor="upload-photo">
                      <div className="profilechild">
                        <img src={UploadIcon} alt="Upload" />
                        <h2>Upload Image</h2>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="upload-photo"
                      accept="image/png"
                      style={{ display: "none" }}
                    />
                  </div>
                  <button type="submit" className="generatebutton">
                    <img src={generateStars} alt="" />
                    Generate Dx
                  </button>
                </div>
              </div>
            </div>
            <div className="dashboardgridtworight">
              <div className="dashboardelementthreetop">
                <h4>
                  Select <strong>Your Favourite Subject</strong>
                </h4>
                <p>
                  Personalize <strong>Your Learning Experience</strong> and{" "}
                  <strong>Unlock Tailored Resources!</strong>
                </p>
              </div>

              <img
                src={subjectsimage}
                alt="Subjects"
                className="subjectsimage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
