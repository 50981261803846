import { apiSlice } from "../apiSlice";

export const historySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query({
      query: () => ({
        url: "api/v1/usegethistory/",
      }),
      providesTags: ["User"],
    }),
  }),
});

export const { useGetHistoryQuery } = historySlice;
