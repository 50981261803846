import React, { useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/aisearch.css";
import { useGetsolveMyQueryQuery } from "../store/General/generalSlice";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";
import { useSolveMyQueryMutation } from "../store/General/generalSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiLoaderCircle } from "react-icons/bi";

const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const DotsIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/dots.svg";
const ThumbsUp =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsup.svg";
const ThumbsDown =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/thumbsdown.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";

const convertUTCtoIST = (utcDate) => {
  const date = new Date(utcDate); // Convert string to Date object
  return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
export default function AiSearch() {
  const { uid } = useParams();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const navigate = useNavigate();

  const { data, isLoading } = useGetsolveMyQueryQuery(uid);
  const { data: score, isLoading: scoreloading } = useGetUserScoreQuery(uid);
  const [query, setquery] = useState();
  const [getquery, getquerystate] = useSolveMyQueryMutation();

  const handleSearch = async () => {
    if (!query) {
      toast.warning("please enter Query!");
      return;
    }
    const formData = new FormData();
    formData.append("query", query);

    const resp = await getquery(formData);
    if (resp?.error) {
      const utcDate = resp?.error?.data?.next;
      const istDate = convertUTCtoIST(utcDate);
      toast.error(`${resp?.error?.data?.error} please try after ${istDate}!`);
    } else {
      navigate(`/aisearch/${resp.data.uuid}`);
    }
  };
  return (
    <div className="homemain">
      <SideBar
        selected="Dashboard"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
            </div>
            <div className="homemainleftouter">
              <h3>Hello Dr.{score?.name},</h3>
              <p>Your patient needs you, Let's rush to the rescue</p>
            </div>
          </div>
          <div className="homemainright">
            <button>
              <div className="homemainrightbuttoncontent">
                <img src={headerbadge} alt="Header Badge" />
                <b>'Novice'</b> Badge
              </div>
              <div className="headerstreaks">
                <img src={fireicon} alt="Fire" />
                <p>{score?.score || 0}</p>
              </div>
            </button>
            <button>
              <img src={reloadicon} alt="Reload" />
            </button>
          </div>
        </div>
        <div className="dashboardbody">
          <div className="dashboardheader  aisearchheader">
            <h2>What's on your mind?</h2>
            <p>
              Example: How do Proteosome Inhibitors function in the treatment of
              Multiple Myeloma?
            </p>
            <div className="dashboardinput">
              <input
                type="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => setquery(e.target.value)}
                defaultValue={data?.data?.query}
                placeholder="Enter your question here"
              />
              {getquerystate?.isLoading ? (
                <BiLoaderCircle className="animate-spin" />
              ) : (
                <img src={DotsIcon} alt="Options" />
              )}
            </div>
          </div>
          <div className="dashboardbodyelements">
            <div
              className="dashboardbodycontent"
              dangerouslySetInnerHTML={{
                __html: data?.data?.result,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
