import React, { useEffect, useState } from "react";
import "../styles/clinicalhome.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import CompletedCases from "../components/completedcases";
import { useNavigate } from "react-router-dom";
import { GiComputerFan } from "react-icons/gi";
import {
  useCheckRunningTestQuery,
  useCreateCaseStudyMutation,
  useGetAllCaseStudyQuery,
  useGetUserScoreQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
const MedicalRecordicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/medicalrecord.svg";
const RandomIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/random.svg";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const BiochemistryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/biochemistry.svg";
const PathologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pathology.svg";
const PharmacologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/pharmacology.svg";
const PhysiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/physiology.svg";
const MicrobiologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/microbiology.svg";
const ImmunologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/immunology.svg";
const PublicIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/public.svg";

const GeneralMedicineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/generalmedicine.svg";

const SurgeryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/surgery.svg";

const CardioIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/cardio.svg";
const EndocrineIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/endocrine.svg";
const GasIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gas.svg";
const BloodIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/blood.svg";
const OncologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/oncology.svg";
const PaediatricsIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/paedtrics.svg";

const GynacIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/gynac.svg";

const MuscleIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/muscle.svg";

const NeurologyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neurology.svg";

const ReproductionIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/reproduction.svg";

const RespiratoryIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/respiratory.svg";

const RenalIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/renal.svg";

const Achivement1 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/1.svg";
const Achivement2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/2.svg";
const Achivement3 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/3.svg";
const Achivement4 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/4.svg";
const Achivement5 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/5.svg";
const Achivement6 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/6.svg";
const Achivement7 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/7.svg";
const Achivement8 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/8.svg";
const Achivement9 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/9.svg";
const Achivement10 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/10.svg";
const GapLine =
  "https://neuro-gen.s3.amazonaws.com/images/icons/badges/line.svg";

const aiimsicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/aiims.svg";
const usmleicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/usmle.svg";
const plabicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/plab.svg";
const neeticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/neet.svg";
const mcqicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/mcq.svg";
const detailedicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/detailed.svg";

export default function ClinicalHome() {
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedExam, setSelectedExam] = useState("");
  const { data: score, isLoading: scoreLoading } = useGetUserScoreQuery();

  const navigate = useNavigate();

  const navigateTo = () => {
    if (selectedValue === 1) {
      navigate(`/clinicalcase/mcq`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (selectedValue === 2) {
      navigate(`/clinicalcase/detailed`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      alert("Please select a question type");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldFetch(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  const { data: runningdata, isLoading: runningLoading } =
    useCheckRunningTestQuery({
      skip: !shouldFetch,
    });
  const [createcase, createcasestate] = useCreateCaseStudyMutation();
  const { data, isLoading } = useGetAllCaseStudyQuery();
  console.log({ data }, "completed data");
  useEffect(() => {
    if (runningdata?.running) {
      navigate(`/clinicalcase/questions/${runningdata?.data[0].uuid}`);
    }
  }, [runningdata]);
  return (
    <div className="homemain">
      <SideBar
        selected="Clinical"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <div className="clinicalbody">
          <div className="clinicalbodyelements clinicalintro">
            <div className="flexrow">
              <img src={MedicalRecordicon} alt="Medical Record" />
              <h4>Welcome to Clinical Case Simulator</h4>
            </div>
            <hr />
            <p>
              Master the Art of Diagnosis, Experience hands-on medical training
              through NeuroGEN’s Clinical Case Simulation. Engage in realistic,
              interactive scenarios that challenge your diagnostic skills,
              clinical reasoning, and decision-making abilities.
              <br />
              Track your progress, earn achievement badges, and elevate your
              expertise in real-world medicine.
            </p>
            <br />

            <p>
              <strong>Transform Learning. Master Medicine.</strong>
            </p>
          </div>
          <div className="clinicalbodyelements">
            <div className="clinicalelementheader">
              <h6>Choose the Subject You Want to Master:</h6>
            </div>
            <hr />
            <div className="clinicalrow">
              <div
                className={
                  selectedSubject === "Random"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Random")}
              >
                <img src={RandomIcon} alt="Random" />
                <p>Random</p>
              </div>
              <div
                className={
                  selectedSubject === "Anatomy"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Anatomy")}
              >
                <img src={AnatomyIcon} alt="Anatomy" />
                <p>Anatomy</p>
              </div>
              <div
                className={
                  selectedSubject === "Physiology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Physiology")}
              >
                <img src={PhysiologyIcon} alt="Physiology" />
                <p>Physiology</p>
              </div>
              <div
                className={
                  selectedSubject === "Biochemistry"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Biochemistry")}
              >
                <img src={BiochemistryIcon} alt="Biochemistry" />
                <p>Biochemistry</p>
              </div>
              <div
                className={
                  selectedSubject === "Pharmacology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Pharmacology")}
              >
                <img src={PharmacologyIcon} alt="Pharmacology" />
                <p>Pharmacology</p>
              </div>
              <div
                className={
                  selectedSubject === "Pathology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Pathology")}
              >
                <img src={PathologyIcon} alt="Pathology" />
                <p>Pathology</p>
              </div>

              <div
                className={
                  selectedSubject === "Microbiology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Microbiology")}
              >
                <img src={MicrobiologyIcon} alt="Microbiology" />
                <p>Microbiology</p>
              </div>
              <div
                className={
                  selectedSubject === "Immunology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Immunology")}
              >
                <img src={ImmunologyIcon} alt="Immunology" />
                <p>Immunology</p>
              </div>
              <div
                className={
                  selectedSubject === "Public"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Public")}
              >
                <img src={PublicIcon} alt="Public Health Sciences" />
                <p>Public Health Sciences</p>
              </div>
              <div
                className={
                  selectedSubject === "General"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("General")}
              >
                <img src={GeneralMedicineIcon} alt="General Medicine" />
                <p>General Medicine</p>
              </div>
              <div
                className={
                  selectedSubject === "Surgery"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Surgery")}
              >
                <img src={SurgeryIcon} alt="Surgery" />
                <p>Surgery</p>
              </div>

              <div
                className={
                  selectedSubject === "Cardiology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Cardiology")}
              >
                <img src={CardioIcon} alt="Cardiology" />
                <p>Cardiology</p>
              </div>

              <div
                className={
                  selectedSubject === "Endocrinology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Endocrinology")}
              >
                <img src={EndocrineIcon} alt="Endocrinology" />
                <p>Endocrinology</p>
              </div>

              <div
                className={
                  selectedSubject === "Gastroenterology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Gastroenterology")}
              >
                <img src={GasIcon} alt="Gastroenterology" />
                <p>Gastroenterology</p>
              </div>

              <div
                className={
                  selectedSubject === "Blood"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Blood")}
              >
                <img src={BloodIcon} alt="Blood" />
                <p>Blood</p>
              </div>

              <div
                className={
                  selectedSubject === "Oncology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Oncology")}
              >
                <img src={OncologyIcon} alt="Oncology" />
                <p>Oncology</p>
              </div>

              <div
                className={
                  selectedSubject === "Paediatrics"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Paediatrics")}
              >
                <img src={PaediatricsIcon} alt="Paediatrics" />
                <p>Paediatrics</p>
              </div>

              <div
                className={
                  selectedSubject === "Gynaecology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Gynaecology")}
              >
                <img src={GynacIcon} alt="Gynaecology" />
                <p>Gynaecology</p>
              </div>

              <div
                className={
                  selectedSubject === "Muscle"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Muscle")}
              >
                <img src={MuscleIcon} alt="Muscle" />
                <p>Muscle</p>
              </div>

              <div
                className={
                  selectedSubject === "Neurology"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Neurology")}
              >
                <img src={NeurologyIcon} alt="Neurology" />
                <p>Neurology</p>
              </div>

              <div
                className={
                  selectedSubject === "Reproduction"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Reproduction")}
              >
                <img src={ReproductionIcon} alt="Reproduction" />
                <p>Reproduction</p>
              </div>

              <div
                className={
                  selectedSubject === "Respiratory"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Respiratory")}
              >
                <img src={RespiratoryIcon} alt="Respiratory" />
                <p>Respiratory</p>
              </div>

              <div
                className={
                  selectedSubject === "Renal"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedSubject("Renal")}
              >
                <img src={RenalIcon} alt="Renal" />
                <p>Renal</p>
              </div>
            </div>
            <div className="clinicalelementheader">
              <h6>Choose the Exam You Want to Master:</h6>
            </div>
            <hr />
            <div className="clinicalrow clinicalrowexams">
              <div
                className={
                  selectedExam === "AIIMS/ INI-CET"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("AIIMS/ INI-CET")}
              >
                <img src={aiimsicon} alt="AIIMS" />
                <p>AIIMS/ INI-CET</p>
              </div>
              <div
                className={
                  selectedExam === "USMLE"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("USMLE")}
              >
                <img src={usmleicon} alt="USMLE" />
                <p>USMLE</p>
              </div>
              <div
                className={
                  selectedExam === "PLAB"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("PLAB")}
              >
                <img src={plabicon} alt="PLAB" />
                <p>PLAB</p>
              </div>
              <div
                className={
                  selectedExam === "NEET-PG"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("NEET-PG")}
              >
                <img src={neeticon} alt="NEET" />
                <p>NEET-PG</p>
              </div>
              <div
                className={
                  selectedExam === "ALL"
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedExam("ALL")}
              >
                <img src={RandomIcon} alt="All" />
                <p>All</p>
              </div>
            </div>
            <div className="clinicalelementheader">
              <h6>Choose the Question Type:</h6>
            </div>
            <hr />
            <div className="clinicalrow clinicalrowtype">
              <div
                className={
                  selectedValue === 1
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedValue(1)}
              >
                <img src={mcqicon} alt="MCQ" />
                <p>MCQ</p>
              </div>
              <div
                className={
                  selectedValue === 2
                    ? "clinicalsubjectselected"
                    : "clinicalsubject"
                }
                onClick={() => setSelectedValue(2)}
              >
                <img src={detailedicon} alt="Elaborative Type" />
                <p>Elaborative Type</p>
              </div>
            </div>
            <button
              className="clinicalbodyelementbutton"
              onClick={async () => {
                const created = await createcase({
                  topics: [selectedSubject],
                  board: [selectedExam],
                  question_type: selectedValue == 1 ? "MCQ" : "EXPLAIN",
                });
                navigate(`/clinicalcase/questions/${created?.data.uuid}`);
              }}
            >
              {createcasestate?.isLoading ? (
                <GiComputerFan className="animate-spin" />
              ) : (
                "Start the Case Study"
              )}
            </button>
          </div>
          <div className="clinicalbodyelements">
            <div className="clinicalelementheader">
              <h4>Achievements</h4>
            </div>
            <hr />
            <div className="clinicalrow">
              <div className="clinicalachievement">
                <img
                  src={Achivement1}
                  alt="Achivement 1"
                  className={`${
                    score?.score < 50 ? "grayscale " : "opacity-50"
                  }`}
                />
              </div>
              <div className="achievementgap">
                <img src={GapLine} alt="Gap Line" />
              </div>
              <div className="clinicalachievement">
                <img
                  src={Achivement2}
                  alt="Achivement 2"
                  className={`${
                    score?.score < 100 ? "grayscale " : "opacity-50"
                  }`}
                />
              </div>
              <div className="achievementgap">
                <img src={GapLine} alt="Gap Line" />
              </div>
              <div className="clinicalachievement">
                <img
                  src={Achivement3}
                  alt="Achivement 3"
                  className={`${score?.score < 250 ? "grayscale" : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement4}
                  alt="Achivement 4"
                  className={`${score?.score < 500 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement5}
                  alt="Achivement 5"
                  className={`${score?.score < 1000 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement6}
                  alt="Achivement 6"
                  className={`${score?.score < 1250 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement7}
                  alt="Achivement 7"
                  className={`${score?.score < 1500 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement8}
                  alt="Achivement 8"
                  className={`${score?.score < 1750 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement9}
                  alt="Achivement 9"
                  className={`${score?.score < 2000 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
              <div className="clinicalachievement">
                <img
                  src={Achivement10}
                  alt="Achivement 10"
                  className={`${score?.score < 2500 ? "grayscale " : ""}`}
                />
              </div>
              <div className="achievementgap"></div>
            </div>
          </div>
          <div className="clinicalcasesrow">
            <div className="clinicalbodyelements">
              <div className="clinicalelementheader">
                <h4>Completed Cases</h4>
              </div>
              <hr />
              <div className="clinicalcolumn">
                {data?.data?.ma}
                <CompletedCases
                  header="65 Year Old Man with Sudden Onset of Shortness of Breath"
                  body="A 65 year old man presents to the emergency department complaining of four days of shortness of breath."
                />
                <CompletedCases
                  header="72 Year Old Woman with Worsening Shortness of Breath"
                  body="A 72 year old woman presents to the outpatient clinic complaining of worsening shortness of breath over the past week."
                />
              </div>
            </div>
            {/* <div className="clinicalbodyelements">
              <div className="clinicalelementheader">
                <h4>Bookmarked Cases</h4>
              </div>
              <hr />
              <div className="clinicalcolumn">
                <CompletedCases
                  header="65 Year Old Man with Sudden Onset of Shortness of Breath"
                  body="A 65 year old man presents to the emergency department complaining of four days of shortness of breath."
                />
                <CompletedCases
                  header="72 Year Old Woman with Worsening Shortness of Breath"
                  body="A 72 year old woman presents to the outpatient clinic complaining of worsening shortness of breath over the past week."
                />
              </div>
            </div> */}
            <div className="clinicalbodyelements">
              <div className="clinicalelementheader">
                <h4>Incomplete Cases</h4>
              </div>
              <hr />
              <div className="clinicalcolumn">
                <CompletedCases
                  header="65 Year Old Man with Sudden Onset of Shortness of Breath"
                  body="A 65 year old man presents to the emergency department complaining of four days of shortness of breath."
                />
                <CompletedCases
                  header="72 Year Old Woman with Worsening Shortness of Breath"
                  body="A 72 year old woman presents to the outpatient clinic complaining of worsening shortness of breath over the past week."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
