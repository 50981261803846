import React from "react";
import "../styles/termsandcondition.css";
import { useNavigate } from "react-router-dom";
export default function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div className="loginmain">
      <div className="conditionscontentbody">
        <div
          className="conditionsbodyheader"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back To Dashboard
        </div>
        <h1>Privacy Policy</h1>
        <p class="last-updated">Last updated: 12/09/24</p>

        <p>
          Welcome to NeuroGEN, a medical education AI-powered Website located at{" "}
          <a href="http://www.NeuroGENN.com">www.NeuroGENN.com</a> (The
          “Website”).
        </p>
        <p>
          This Website is owned and operated by Shiv Virmani - (The Merchant
          legal entity name). By using our Website, you agree to the Website
          terms and conditions (The “Terms”) and Privacy Policy (the “Policy”).
          If you do not agree to the Terms, policies and conditions of these
          Terms, any additional documents including this Privacy Policy you may
          not use the Website. Shiv Virmani reserves the right to modify this
          Privacy Policy at any time. It is your responsibility to review the
          Privacy Policy before each use of the Website.
        </p>
        <p>
          NeuroLAB AI utilizes OpenAI, L.L.C’s (“OpenAI”) API. OpenAI’s Privacy
          Policy can be found at{" "}
          <a href="https://openai.com/policies/privacy-policy">
            https://openai.com/policies/privacy-policy
          </a>{" "}
          and applies to usage of the Website. If or when OpenAI’s and our
          policies conflict, the Privacy Policy written in this document
          applies.
        </p>

        <h2>1. What Information Do We Collect?</h2>
        <p>
          We collect your personal data whenever you interact with us and when
          you use the App or visit our Website. Generally, we will collect and
          process the following information:
        </p>
        <ul>
          <li>
            <strong>Account Information:</strong> If you create an account with
            us, we will collect information associated with your account,
            including your name, contact information, account credentials,
            payment card information, and use history, (collectively “Account
            Information”).
          </li>
          <li>
            <strong>User Content:</strong> When you use our Website, we may
            collect Personal and Usage Information that is included in the
            input, output, file uploads, or feedback that you provide to our
            Website (“Content”).
          </li>
          <li>
            <strong>Communication Information:</strong> If you communicate with
            us, we may collect your name, contact information, and the contents
            of any messages you send (“Communication Information”).
          </li>
          <li>
            <strong>Social Media Information:</strong> We have pages on social
            media Websites like Instagram, Facebook, Twitter, YouTube, and
            LinkedIn. When you interact with our social media pages, we will
            collect Personal Information that you elect to provide to us, such
            as your contact details (collectively, “Social Information”).
          </li>
        </ul>
        <p>
          We also automatically collect certain information when you access,
          use, or interact with us. We generally collect the following
          information:
        </p>
        <ul>
          <li>
            <strong>Device information:</strong> Includes name of the device,
            operating system, and browser you are using. Information collected
            may depend on the type of device you use and its settings.
          </li>
          <li>
            <strong>Usage information:</strong> We may automatically collect
            information about your use of the Website, such as the types of
            content that you view or engage with, the features you use and the
            actions you take, as well as your time zone, country, the dates and
            times of access, user agent and version, type of computer or mobile
            device, computer connection, IP address, and the like.
          </li>
          <li>
            <strong>Analytics:</strong> We may use a variety of online analytics
            products that use cookies to help us analyze how users use our
            Services and enhance your experience when you use the Services.
          </li>
        </ul>
        <p>
          These data may be managed or collected through third-party service
          providers such as Google Analytics, Auth0, Paddle, Cashfree, and
          Microsoft Clarity whose terms of use and privacy policy can be found
          on their respective websites.
        </p>

        <h2>2. What Do We Do With the Information We Collect?</h2>
        <p>
          We will ask you for personal data in certain fields on the Website or
          in the App that we need for you to use the Services. The personal data
          we collect is used only for the purpose we state at the time of
          collection or for purposes listed below. For example, our uses may
          include, but are not limited to, the following:
        </p>
        <ul>
          <li>To manage internal business practices</li>
          <li>To provide support or other services</li>
          <li>
            To provide information based on your needs and respond to your
            requests
          </li>
          <li>To administer products and services</li>
          <li>
            To select content, improve quality, and facilitate the use of our
            Website, App, and Services
          </li>
          <li>To assess usage of products and services</li>
          <li>To communicate with you about events</li>
          <li>
            To update you on relevant products, services, offers, and
            opportunities
          </li>
          <li>To engage with third parties</li>
          <li>
            To protect our content and services from illegal or harmful
            activities
          </li>
          <li>To get feedback and input from you</li>
          <li>
            To protect our information assets as well as your personal data
          </li>
          <li>To assist in business sales or mergers</li>
          <li>To comply with Laws</li>
        </ul>

        <h2>3. Who Can Use the Information We Collect and How?</h2>
        <p>
          We may provide your personal data to third parties, or third parties
          may collect personal data from you on our behalf if we have contracted
          with that third party to provide some part of the information or
          service that you have requested. Other than those who act on our
          behalf, and except as explained in this Policy, personal data you
          provide to us will not be transferred to unrelated third parties
          unless we have a legal basis to do so. However, please note that the
          personal data you transmit to us may be subject to disclosure pursuant
          to judicial or other government subpoenas, warrants, or orders.
        </p>

        <h2>4. What Are Your Rights Regarding Your Personal Data?</h2>
        <p>
          We respect your right to access and control your personal data, and
          you have choices about the data we collect from you. If we request
          personal data from you that is not necessary for the purposes of
          providing you with our products and services, then you may decline to
          provide that personal data. However, if you choose not to provide
          personal data that is necessary to provide a particular service, you
          may not have access to certain features of that service.
        </p>
        <p>
          We process your personal data on the following legal bases: consent
          (which you may withdraw at any time by contacting{" "}
          <a href="mailto:neuroverse.healthcare@gmail.com">
            neuroverse.healthcare@gmail.com
          </a>
          ), the necessity to enter into and perform our contract with you,
          compliance with our legal obligations, protection of your vital
          interests, for the performance of a task carried out in the public
          interest, and for our legitimate interests, which do not override your
          fundamental rights and freedoms.
        </p>
        <p>
          Regardless of where you live, you can always opt-out of marketing
          communications, correct or update your information, and implement
          technical measures to opt-out of targeted or behavioral advertising as
          outlined below:
        </p>
        <ul>
          <li>
            <strong>Opt-Out of Email Marketing Preferences:</strong> The email
            communications we send you will generally provide an unsubscribe
            link, allowing you to opt-out of receiving future email or to change
            your contact preferences.
          </li>
        </ul>

        <h2>5. How Do We Protect the Personal Data We Collect?</h2>
        <p>
          We are committed to protecting the security of your personal data. We
          use technologies and procedures to protect your personal data. We
          review our strategies and methods and update them as necessary to meet
          our business needs, changes in technology, and regulatory
          requirements.
        </p>
        <p>
          Your personal data may be transferred to, and processed in, countries
          other than the country in which you are resident. These countries may
          have data protection laws that are different from the laws of your
          country. However, we have taken appropriate safeguards to require that
          your personal data will remain protected in accordance with this
          Privacy Policy.
        </p>

        <h2>6. How Long Do We Retain the Data?</h2>
        <p>
          We retain personal data for as long as necessary to provide our
          Services and fulfill the transactions you have requested, or for other
          business purposes such as complying with our legal obligations,
          resolving disputes, and enforcing our agreements.
        </p>

        <h2>7. What is Our Policy if You Are an Underage Child?</h2>
        <p>
          Our Website is only for those over the age of 13. If you are below the
          age of 18, explicit parental consent is required to use the services.
        </p>

        <h2>8. What Happens When You Link to a Third-Party Website?</h2>
        <p>
          If you click on a link and go to another Website, you will be subject
          to that Website’s privacy policy. We encourage you to read the posted
          privacy policy statement at any Website before submitting any personal
          data.
        </p>

        <h2>9. What Happens When We Update Our Privacy Policy?</h2>
        <p>
          We may update our Privacy Policy from time to time to keep current
          with evolving legal requirements. Your acceptance of any minor changes
          to this Privacy Policy is indicated by your continued use of our
          Services. If you disagree with any changes, you may discontinue using
          the Services.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          For more information, or if you have any questions regarding this
          Privacy Policy, please contact us at{" "}
          <a href="mailto:neuroverse.healthcare@gmail.com">
            neuroverse.healthcare@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
