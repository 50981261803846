import React, { useState, useEffect } from "react";
import SideBar from "../elements/sidebar";
import "../styles/profile.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../store/authSlice";
import config from "../config/APIEndpoints";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../store/profile/UserApiSlice";
import { useGetHistoryQuery } from "../store/History/History";
import { useFormik } from "formik";
import * as Yup from "yup";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const ProfileIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/profile.svg";
const membershipTick =
  "https://neuro-gen.s3.amazonaws.com/images/icons/membershiptick.svg";

export const DetailsSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(null);
  const roles = [
    "Medical Aspirant",
    "Medical Student",
    "Intern",
    "Junior Resident",
    "Senior Resident",
    "Medical Researcher",
    "Consultant",
    "Clinical Specialist",
    "Fellowship",
    "Gene Scientist",
    "Clinical Psychologist",
    "Nutritionist",
    "Medical Educator",
    "Healthcare IT Professional",
    "Paramedic",
    "Public Health Professional",
    "Healthcare administrator",
    "Others",
  ];
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSpecialisation, setSelectedSpecialisation] = useState("");
  const [file, setFile] = useState(null);
  const { data, isLoading } = useGetProfileQuery();
  const [updateprofile, setupdateprofile] = useUpdateProfileMutation();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    setFile(event.target.files[0]);
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);
    }
  };

  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleChangeSpecialisation = (e) => {
    setSelectedSpecialisation(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      specialization: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      role: Yup.string().required("Please select your role"),
      specialization: Yup.string().required(
        "Please select your specialisation"
      ),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      if (file) formData.append("profile_pic", file);
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("email", values.email);
      formData.append("phone_number", values.phoneNumber);
      formData.append("role", values.role);
      formData.append("specialization", values.specialization);
      updateprofile(formData);
      // Submit the formData to your API endpoint here
    },
  });
  useEffect(() => {
    formik.setValues({
      firstName: data?.profile?.first_name,
      lastName: data?.profile?.last_name,
      email: data?.profile?.email,
      phoneNumber: data?.profile?.phone_number,
      role: data?.profile?.role,
      specialization: data?.profile?.specialization,
    });
  }, [data]);
  return (
    <>
      <div className="profilebodyelement">
        <div className="profilerow">
          <div className="profileleft">
            <label htmlFor="upload-photo">
              <img
                src={
                  filePreview ||
                  `${config.getEndpoint()}${data?.profile?.profile_pic}` ||
                  ProfileIcon
                }
                alt="Profile"
                className="upload-icon"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "2px solid #ccc",
                }}
              />
            </label>
            <input
              type="file"
              id="upload-photo"
              accept="image/png"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <div className="profileright">
            <h3>Upload Your Photo</h3>
            <p>Your photo should be in PNG format</p>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="profileinput">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error text-red-500">
                {formik.errors.firstName}
              </div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error text-red-500">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              disabled={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error text-red-500">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>Current Role</label>
            <div className="inputdivselect">
              <select
                id="role"
                name="role"
                value={formik.values.role}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleChange(e);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="" disabled>
                  Select your role
                </option>
                {roles.map((role, index) => (
                  <option key={index} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.role && formik.errors.role ? (
              <div className="error text-red-500">{formik.errors.role}</div>
            ) : null}
          </div>
          <div className="profileinput">
            <label>specialization</label>
            <div className="inputdivselect">
              <select
                id="specialization"
                name="specialization"
                value={formik.values.specialization}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleChangeSpecialisation(e);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="" disabled>
                  Select your Specialisation
                </option>
                {roles.map((specialisation, index) => (
                  <option key={index} value={specialisation}>
                    {specialisation}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.specialisation && formik.errors.specialisation ? (
              <div className="error text-red-500">
                {formik.errors.specialisation}
              </div>
            ) : null}
          </div>
          <button
            onClick={formik.handleSubmit}
            type="submit"
            className="profilesubmit"
          >
            Save
          </button>
        </form>
      </div>
      {/* <div className="profilebodyelement profilebodyelementrow">
        <div className="profilebodyrowleft">
          <h3>Switch to Dark Mode</h3>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
        <div className="profilebodyrowright">
          <div className="inputswitch">
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div> */}
      <div className="profilebodyelement profilebodyelementrow">
        <div className="profilebodyrowleft">
          <h3>Change Password</h3>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
        <div className="profilebodyrowright">
          <div className="inputswitch">
            <button className="changepasswordprofile">Change</button>
          </div>
        </div>
      </div>
      <div className="profilebodyelement profilebodyelementrow">
        <div className="profilebodyrowleft">
          <h3>Logout</h3>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
        <div className="profilebodyrowright">
          <div className="inputswitch">
            <button
              className="logoutprofile"
              onClick={() => {
                dispatch(logOut());
                navigate("/");
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export const MembershipSection = () => {
  const [selectedItem, setSelectedItem] = useState("Monthly");
  return (
    <>
      <div className="membershiptop">
        <button
          onClick={() => setSelectedItem("Yearly")}
          className={selectedItem === "Yearly" ? "selectedmembership" : ""}
        >
          Yearly
        </button>
        <button
          onClick={() => setSelectedItem("Monthly")}
          className={selectedItem === "Monthly" ? "selectedmembership" : ""}
        >
          Monthly
        </button>
      </div>
      <div className="membershiprow">
        <div className="membershipcard">
          <div className="membershipcardtop">
            <h4>Free</h4>
            <p>Best For Personal Use</p>
          </div>
          <div className="membershippricing">
            <h3>
              <h3 className="strikethrough">₹100</h3> 0
            </h3>

            {
              {
                Yearly: <p>/Year</p>,
                Monthly: <p>/month</p>,
              }[selectedItem]
            }
          </div>

          <div className="membershipcardbottom">
            <h5>What you get:</h5>
            <ul>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
            </ul>
          </div>
          <button>Get Started</button>
        </div>
        <div className="membershipcard">
          <div className="membershipcardtop">
            <h4>Average</h4>
            <p>Best For Personal Use</p>
          </div>
          <div className="membershippricing">
            <h3>₹99</h3>

            {
              {
                Yearly: <p>/Year</p>,
                Monthly: <p>/month</p>,
              }[selectedItem]
            }
          </div>

          <div className="membershipcardbottom">
            <h5>All free features, plus:</h5>
            <ul>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
            </ul>
          </div>
          <button>Get Started</button>
        </div>
        <div className="membershipcard">
          <div className="popularcardpick">
            <p>Most Popular</p>
          </div>
          <div className="membershipcardtop">
            <h4>Scholar</h4>
            <p>Best For Personal Use</p>
          </div>
          <div className="membershippricing">
            <h3>
              <h3>₹120</h3>
            </h3>

            {
              {
                Yearly: <p>/Year</p>,
                Monthly: <p>/month</p>,
              }[selectedItem]
            }
          </div>

          <div className="membershipcardbottom">
            <h5>All starter features, plus:</h5>
            <ul>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
            </ul>
          </div>
          <button>Get Started</button>
        </div>
        <div className="membershipcard">
          <div className="membershipcardtop">
            <h4>Visinory</h4>
            <p>Best For Personal Use</p>
          </div>
          <div className="membershippricing">
            <h3>
              <h3>₹299</h3>
            </h3>

            {
              {
                Yearly: <p>/Year</p>,
                Monthly: <p>/month</p>,
              }[selectedItem]
            }
          </div>

          <div className="membershipcardbottom">
            <h5>All business features, plus:</h5>
            <ul>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
              <li>
                <img src={membershipTick} alt="Get" />
                <p>Lorem ipsum dolor sit amet</p>
              </li>
            </ul>
          </div>
          <button>Get Started</button>
        </div>
      </div>
    </>
  );
};
const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
export const HistorySection = ({ handleViewAllClick }) => {
  const { data, isLoading } = useGetHistoryQuery();
  const [groupres, setgroupres] = useState();
  const [diagnosis, setdiagnosis] = useState();

  useEffect(() => {
    const groupedResults = data?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setgroupres(groupedResults);

    const groupdiagnosis = data?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setdiagnosis(groupdiagnosis);
  }, [data]);
  return (
    <>
      <div className="historybody">
        <div className="historysections">
          <div className="historyelement">
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Search</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Search");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {groupres &&
              Object.entries(groupres).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p key={item.uuid}>{item.query}</p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div className="historyelement">
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Diagnose</h2>
              </div>
              <div className="historyelementright">
                {/* <button className="elementselector">Clear History</button> */}
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Diagnose");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            {diagnosis &&
              Object.entries(diagnosis).map(([date, items]) => (
                <div key={date}>
                  <h5>{date}</h5>
                  <div className="searchresulthistory">
                    {items.map((item) => (
                      <p key={item.uuid}>
                        {item?.quick_query || item?.chief_complaints}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="historyelement">
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Research</h2>
              </div>
              <div className="historyelementright">
                <button className="elementselector">Clear History</button>
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Research");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            <h5>18/08/2024</h5>
            <div className="searchresulthistory">
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
              <h5>17/08/2024</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
            </div>
          </div>
          <div className="historyelement">
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Expert Advice</h2>
              </div>
              <div className="historyelementright">
                <button className="elementselector">Clear History</button>
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Expert Advice");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            <h5>18/08/2024</h5>
            <div className="searchresulthistory">
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
              <h5>17/08/2024</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
            </div>
          </div>
          <div className="historyelement">
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Bookmark cases</h2>
              </div>
              <div className="historyelementright">
                <button className="elementselector">Clear History</button>
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Bookmark cases");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            <h5>18/08/2024</h5>
            <div className="searchresulthistory">
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
              <h5>17/08/2024</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
            </div>
          </div>
          <div className="historyelement">
            <div className="historyelementheader">
              <div className="historyelementleft">
                <h2>Completed Cases</h2>
              </div>
              <div className="historyelementright">
                <button className="elementselector">Clear History</button>
                <button
                  className="elementselectorfilled"
                  onClick={() => {
                    handleViewAllClick("Completed Cases");
                  }}
                >
                  View all
                </button>
              </div>
            </div>
            <hr />
            <h5>18/08/2024</h5>
            <div className="searchresulthistory">
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
              <h5>17/08/2024</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Ipsum viverra facilisis
                consectetur tortor phasellus mauris justo
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export const AllSearch = ({ searchTerm, diagnosis, groupres }) => {
  return (
    <>
      <div className="historybody">
        <div className="historyelement">
          <div className="historyelementheader">
            <div className="historyelementleft">
              <h2>{searchTerm}</h2>
            </div>
            <div className="historyelementright">
              {/* <button className="elementselector">Clear History</button> */}
            </div>
          </div>
          <hr />
          {searchTerm == "Search" &&
            groupres &&
            Object.entries(groupres).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p key={item.uuid}>{item.query}</p>
                  ))}
                </div>
              </div>
            ))}
          {diagnosis &&
            Object.entries(diagnosis).map(([date, items]) => (
              <div key={date}>
                <h5>{date}</h5>
                <div className="searchresulthistory">
                  {items.map((item) => (
                    <p key={item.uuid}>
                      {item?.quick_query || item?.chief_complaints}
                    </p>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default function Profile() {
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  const [selectedItem, setSelectedItem] = useState("Details");
  const [selectedSearch, setSelectedSearch] = useState("");
  const handleViewAllClick = (searchType) => {
    setSelectedItem("ViewAll");
    setSelectedSearch(searchType);
  };
  const [groupres, setgroupres] = useState();
  const [diagnosis, setdiagnosis] = useState();
  const { data, isLoading } = useGetHistoryQuery();

  useEffect(() => {
    const groupedResults = data?.search?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setgroupres(groupedResults);

    const groupdiagnosis = data?.diagnosis?.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    setdiagnosis(groupdiagnosis);
  }, [data]);

  return (
    <div className="homemain">
      <SideBar
        selected="Profile"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        {" "}
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
          </div>
          <div className="profileheader">
            <p onClick={() => navigate("/home")}>Back to Dashboard </p>
            <h3>Edit Profile</h3>
          </div>
        </div>
        <div className="profilesections">
          <ul>
            <li
              className={selectedItem === "Details" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("Details")}
            >
              <p>Details</p>
            </li>
            <li
              className={selectedItem === "Membership" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("Membership")}
            >
              <p>Membership</p>
            </li>
            <li
              className={selectedItem === "History" ? "selectedProfile" : ""}
              onClick={() => setSelectedItem("History")}
            >
              <p>History</p>
            </li>
          </ul>
        </div>
        <div className="profilebody">
          {
            {
              Details: <DetailsSection />,
              Membership: <MembershipSection />,
              History: (
                <HistorySection handleViewAllClick={handleViewAllClick} />
              ),
              ViewAll: (
                <AllSearch
                  searchTerm={selectedSearch}
                  groupres={groupres}
                  diagnosis={diagnosis}
                />
              ),
            }[selectedItem]
          }
        </div>
      </div>
    </div>
  );
}
