import React, { useState } from "react";
import "../styles/lounge.css";
import SideBar from "../elements/sidebar";
import { useNavigate } from "react-router-dom";
import LoungeQuestion from "../elements/loungequestion";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";
const ArrowupRight =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/arrowupright.svg";
const Caseimg =
  "https://neuro-gen.s3.amazonaws.com/images/icons/dashboard/case.png";
const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";

const LeaderBoardTopIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/leaderboardtop.svg";
const LeaderBoardPlayerIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/leaderboardplayer.svg";
const CrownIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/crown.svg";
const liker1 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/liker1.svg";
const liker2 =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/liker2.svg";
const speakericon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/speaker.svg";
const questionimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/question.svg";
const upvoteicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/upvoted.svg";
const downvoteicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/downvote.svg";
const refreshicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/refresh.svg";
const commenticon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/comment.svg";
const forwardicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/forward.svg";
const quizicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/quiz.svg";

export default function Lounge() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const navigate = useNavigate();
  const [selectTimeline, setSelectTimeline] = useState("Week");

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );

  return (
    <div className="homemain">
      <SideBar
        selected="Lounge"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="homemainheader">
          <div className="homemainleft">
            <div className="homemainleftinner">
              <div className="closesidebarbutton" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                  alt="Arrow"
                />
              </div>
            </div>
            <div className="homemainleftouter">
              <h3>Doctors Lounge</h3>
            </div>
          </div>
          <div className="homemainright">
            <button>
              <div className="homemainrightbuttoncontent">
                <img src={headerbadge} alt="Header Badge" />
                <b>'Novice'</b> Badge
              </div>
              <div className="headerstreaks">
                <img src={fireicon} alt="Fire" />
                <p>200</p>
              </div>
            </button>
            <button>
              <img src={reloadicon} alt="Reload" />
            </button>
          </div>
        </div>
        <div className="loungebodycontent">
          <div className="loungebodycontentleft">
            {" "}
            <div className="dashboardcase">
              <div className="dashboardcaseleft">
                <h2>Case Of the Day</h2>
                <p className="dashboardp">
                  Lorem ipsum dolor sit amet consectetur. Diam elementum sed
                  etiam ultrices aliquet eu
                </p>
                <button
                  onClick={() => {
                    navigate("/cases");
                  }}
                >
                  <p>View Case</p>
                  <img src={ArrowupRight} alt="Link" />
                </button>
              </div>
              <div className="dashboardcaseright">
                <img src={Caseimg} alt="Case" />
              </div>
            </div>
            <div className="loungequestioncard">
              <div className="loungequestioncardtop">
                <img src={liker1} alt="Liked" />
                <img src={liker2} alt="Liked" />
                <p>Adam D’Angelo and Pete Lada upvoted · 2h</p>
              </div>
              <div className="loungecardspeaker">
                <div className="loungecardspeakerleft">
                  <img src={speakericon} alt="Speaker" />
                </div>
                <div className="loungecardspeakerright">
                  <div className="loungecardspeakerrighttop">
                    <h5>Pablo Garces</h5>
                    <h5>·</h5>
                    <button>Follow</button>
                  </div>
                  <div className="loungecardspeakerrightbottom">
                    <p>Product Designer at Quora · Updated Saturday</p>
                  </div>
                </div>
              </div>
              <div className="loungecardquestion">
                <h3>
                  This is a question or post title, what do you want to ask?
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard
                </p>
              </div>
              <img src={questionimage} alt="" className="questionimage" />
              <div className="loungequestioncardtop">
                <img src={liker1} alt="Liked" />
                <img src={liker2} alt="Liked" />

                <p>Adam D’Angelo and Pete Lada upvoted · 2h</p>
              </div>
              <div className="loungecardbottom">
                <div className="loungecardbottomleft">
                  <div className="upvotecontainer">
                    <div className="upvote">
                      <img src={upvoteicon} alt="Upvoted" />
                      <p>151</p>
                    </div>
                    <hr />
                    <div className="downvote">
                      <img src={downvoteicon} alt="Downvote" />
                    </div>
                  </div>
                  <img src={refreshicon} alt="Refresh" />
                  <img src={commenticon} alt="Comment" onClick={togglePopup} />
                  <LoungeQuestion
                    isVisible={isPopupVisible}
                    onClose={togglePopup}
                  />
                </div>
                <div className="loungecardbottomright">
                  <img src={forwardicon} alt="Forward" />
                </div>
              </div>
            </div>
          </div>
          <div className="loungebodycontentright">
            <div className="loungeleaderboard">
              <h4>Leaderboard</h4>
              <div className="loungeleaderboardtimeline">
                <button
                  className={
                    selectTimeline === "All Time" ? "leaderboardactive" : ""
                  }
                  onClick={() => setSelectTimeline("All Time")}
                >
                  All Time
                </button>
                <button
                  className={
                    selectTimeline === "Week" ? "leaderboardactive" : ""
                  }
                  onClick={() => setSelectTimeline("Week")}
                >
                  Week
                </button>
                <button
                  className={
                    selectTimeline === "Month" ? "leaderboardactive" : ""
                  }
                  onClick={() => setSelectTimeline("Month")}
                >
                  Month
                </button>
              </div>
              <div className="leaderboardbodytop">
                <div className="leaderboardbodytopleft">
                  <div className="leaderboardposition">2</div>
                  <div className="leaderboardcircleouter">
                    <div className="leaderboardcircleinner">
                      <img src={LeaderBoardTopIcon} alt="Profile" />
                    </div>
                  </div>
                  <div className="leaderboardname">Dr. Shiv</div>
                  <div className="leaderboardscore">
                    <img src={fireicon} alt="Streak" />
                    <p>1900 Pt</p>
                  </div>
                </div>
                <div className="leaderboardbodytopmiddle">
                  <div className="leaderboardcrown">
                    <img src={CrownIcon} alt="Crown" />
                  </div>
                  <div className="leaderboardcircleouter">
                    <div className="leaderboardcircleinner">
                      <img src={LeaderBoardTopIcon} alt="Profile" />
                    </div>
                  </div>
                  <div className="leaderboardname">Dr. Shiv</div>
                  <div className="leaderboardscore">
                    <img src={fireicon} alt="Streak" />
                    <p>1900 Pt</p>
                  </div>
                </div>
                <div className="leaderboardbodytopright">
                  <div className="leaderboardposition">3</div>
                  <div className="leaderboardcircleouter">
                    <div className="leaderboardcircleinner">
                      <img src={LeaderBoardTopIcon} alt="Profile" />
                    </div>
                  </div>
                  <div className="leaderboardname">Dr. Shiv</div>
                  <div className="leaderboardscore">
                    <img src={fireicon} alt="Streak" />
                    <p>1900 Pt</p>
                  </div>
                </div>
              </div>
              <div className="leaderboardplayers">
                <div className="leaderboardplayerelement">
                  <div className="leaderboardplayerleftelement">
                    <div className="leaderboardplayerprofile">
                      <img src={LeaderBoardPlayerIcon} alt="Profile" />
                      <div className="leaderboardplayerposition">4</div>
                    </div>
                    <p>Jane Cooper</p>
                  </div>
                  <div className="leaderboardplayerrightelement">
                    {" "}
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>1900 Pt</p>
                    </div>
                  </div>
                </div>
                <div className="leaderboardplayerelement">
                  <div className="leaderboardplayerleftelement">
                    <div className="leaderboardplayerprofile">
                      <img src={LeaderBoardPlayerIcon} alt="Profile" />
                      <div className="leaderboardplayerposition">5</div>
                    </div>
                    <p>Jane Cooper</p>
                  </div>
                  <div className="leaderboardplayerrightelement">
                    {" "}
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>1900 Pt</p>
                    </div>
                  </div>
                </div>
                <div className="leaderboardplayerelement">
                  <div className="leaderboardplayerleftelement">
                    <div className="leaderboardplayerprofile">
                      <img src={LeaderBoardPlayerIcon} alt="Profile" />
                      <div className="leaderboardplayerposition">6</div>
                    </div>
                    <p>Jane Cooper</p>
                  </div>
                  <div className="leaderboardplayerrightelement">
                    {" "}
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>1900 Pt</p>
                    </div>
                  </div>
                </div>
                <div className="leaderboardplayerelement">
                  <div className="leaderboardplayerleftelement">
                    <div className="leaderboardplayerprofile">
                      <img src={LeaderBoardPlayerIcon} alt="Profile" />
                      <div className="leaderboardplayerposition">7</div>
                    </div>
                    <p>Jane Cooper</p>
                  </div>
                  <div className="leaderboardplayerrightelement">
                    {" "}
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>1900 Pt</p>
                    </div>
                  </div>
                </div>
                <div className="leaderboardplayerelement">
                  <div className="leaderboardplayerleftelement">
                    <div className="leaderboardplayerprofile">
                      <img src={LeaderBoardPlayerIcon} alt="Profile" />
                      <div className="leaderboardplayerposition">8</div>
                    </div>
                    <p>Jane Cooper</p>
                  </div>
                  <div className="leaderboardplayerrightelement">
                    {" "}
                    <div className="leaderboardscore">
                      <img src={fireicon} alt="Streak" />
                      <p>1900 Pt</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quizcase ">
          <div className="dashboardcaseleft quizcaseleft">
            <div className="quizcasetopleft">
              <h4>Get Started with Quiz</h4>
              <p className="dashboardp">
                Lorem ipsum dolor sit amet consectetur. Erat neque ut et quis
                scelerisque gravida tellus. Malesuada quis id quam morbi egestas
                eu aenean. Amet eget lacus.
              </p>
            </div>
            <button
              className="elementselector"
              onClick={() => {
                navigate("/clinical");
              }}
            >
              <p>Start Now!!</p>
            </button>
          </div>
          <div className="dashboardcaseright">
            <img src={quizicon} alt="Case" />
          </div>
        </div>
        <div className="loungebottom">
          <div className="loungebottomelement">
            <div className="loungebottomelementoverlay">
              <p>PODCAST</p>
              <h3>Healing Harmony Podcast</h3>
            </div>
          </div>
          <div className="loungebottomelement">
            <div className="loungebottomelementoverlay">
              <p>BLOG</p>
              <h3>Healing Powers of Herbal Remedies for Stronger Bones</h3>
            </div>
          </div>
          <div className="loungebottomelement">
            <div className="loungebottomelementoverlay">
              <p>FEATURED</p>
              <h3>Artificial Heart</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
