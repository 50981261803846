import React, { useEffect, useState } from "react";
import "../styles/clinicalmcq.css";
import HomemainHeader from "../components/homemainheader";
import SideBar from "../elements/sidebar";
import { BsFillPatchCheckFill } from "react-icons/bs";

import CorrectAnswer from "../components/correctanswer";
import WrongAnswer from "../components/wronganswer";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetQuestionsQuery,
  useGetRunningTestQuery,
} from "../store/caseSimulation/CaseStudyApiSlice";
import { useSubmitQuestionMutation } from "../store/caseSimulation/CaseStudyApiSlice";
import { FaCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import SkippedAnser from "../components/skippedanswer";
const AnatomyIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/subjects/anatomy.svg";
const TimeIcon = "https://neuro-gen.s3.amazonaws.com/images/icons/time.svg";
function CaseStudySkeletton() {
  return (
    <div class="space-y-4 p-4">
      <div class="animate-pulse space-y-2">
        <div class="h-8 bg-gray-200 rounded w-3/4"></div>

        <div class="h-6 bg-gray-200 rounded w-1/4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-1/6"></li>
          <li class="h-4 bg-gray-200 rounded w-1/4"></li>
          <li class="h-4 bg-gray-200 rounded w-1/3"></li>
          <li class="h-4 bg-gray-200 rounded w-1/2"></li>
        </ul>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <div class="space-y-2">
          <div class="h-4 bg-gray-200 rounded w-full"></div>
          <div class="h-4 bg-gray-200 rounded w-5/6"></div>
          <div class="h-4 bg-gray-200 rounded w-4/6"></div>
        </div>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-3/4"></li>
          <li class="h-4 bg-gray-200 rounded w-2/3"></li>
          <li class="h-4 bg-gray-200 rounded w-1/2"></li>
        </ul>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-5/6"></li>
          <li class="h-4 bg-gray-200 rounded w-3/4"></li>
          <li class="h-4 bg-gray-200 rounded w-2/3"></li>
        </ul>

        <div class="h-6 bg-gray-200 rounded w-1/4 mt-4"></div>
        <ul class="space-y-2">
          <li class="h-4 bg-gray-200 rounded w-4/5"></li>
          <li class="h-4 bg-gray-200 rounded w-3/5"></li>
          <li class="h-4 bg-gray-200 rounded w-2/5"></li>
        </ul>
      </div>
    </div>
  );
}

function QuestSkeleton() {
  return (
    <div class="space-y-4 p-4">
      <div class="animate-pulse">
        <div class="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
      </div>
      <hr class="border-gray-200" />

      <div class="animate-pulse space-y-2">
        <div class="h-5 bg-gray-200 rounded w-3/4"></div>

        <div class="space-y-4 mt-4">
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-3/5"></div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-4/5"></div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="h-4 w-4 bg-gray-200 rounded-full"></div>
            <div class="h-4 bg-gray-200 rounded w-3/6"></div>
          </div>
        </div>

        <div class="h-8 bg-gray-200 rounded w-1/4 mt-4"></div>
      </div>
    </div>
  );
}
function Question({ question, type, submitquestion }) {
  const [answer, setanswer] = useState();
  const [explain, setexplain] = useState();
  const [submit, setsubmit] = useState();
  useEffect(() => {
    setsubmit(false);
    if (question.status == "ANSWERED" && type == "MCQ") {
      setsubmit(true);
      setanswer(question?.answer_option);
    } else if (question.status == "ANSWERED" && type == "EXPLAIN") {
      setsubmit(true);
      setexplain(question?.answer_explain);
    } else {
      setanswer();
      setexplain("");
    }
  }, [question]);

  return (
    <div className=" w-full flex flex-col">
      <label className="text-[14px]">{question?.question}</label>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (type == "MCQ") {
            submitquestion({
              uid: question?.uuid,
              updateobject: {
                answer_option: answer,
                status: "ANSWERED",
              },
            });
            if (answer == question.correct_option) {
              toast.success("🔥Correct! +4");
            } else {
              toast.error("👎InCorrect! -1");
            }
          } else {
            submitquestion({
              uid: question?.uuid,
              updateobject: {
                answer_explain: explain,
                status: "ANSWERED",
              },
            });
          }
          setsubmit(true);
        }}
      >
        <div className="clinicalbodyoptions">
          {type == "MCQ" &&
            question?.options?.map((rec, index) => (
              <div className="clinicalbodyoption mt-[17px]">
                <input
                  type="radio"
                  name="clinicalOption"
                  id={index}
                  value={rec}
                  checked={answer === index}
                  onChange={() => setanswer(index)}
                  disabled={question?.status == "ANSWERED" ? true : false}
                  required
                />
                <label htmlFor={index}>{rec}</label>
                {submit && index == question?.correct_option && (
                  <FaCheck className="text-green-500" />
                )}
              </div>
            ))}
        </div>
        <div className="clinicalelementbody">
          <div className="clinicalelementbodyheader headerbodybottom">
            {type == "EXPLAIN" && (
              <textarea
                required
                placeholder="Enter Your Answer..."
                value={explain}
                disabled={submit}
                onChange={(e) => setexplain(e.target.value)}
              ></textarea>
            )}{" "}
          </div>{" "}
        </div>
        {!submit && question?.status != "SKIPPED" && (
          <button type="submit" className="clinicalmcqsubmit mt-[16px]">
            Submit
          </button>
        )}
        {submit && type == "MCQ" && answer == question?.correct_option && (
          <CorrectAnswer explain={question?.reason} />
        )}
        {submit && type == "MCQ" && answer != question?.correct_option && (
          <WrongAnswer explain={question?.reason} />
        )}
        {type == "MCQ" && question?.status == "SKIPPED" && (
          <SkippedAnser explain={question?.reason} />
        )}
        {submit && type == "EXPLAIN" && "Answered! Please move Forward"}
      </form>
    </div>
  );
}
function Questions({ questions, type }) {
  const [submitquestion, submitquestionState] = useSubmitQuestionMutation();

  const [currentindex, setcurrentindex] = useState(0);
  const [currentquestion, setcurrentquestion] = useState(
    questions?.length > 0 ? questions[currentindex] : null
  );
  useEffect(() => {
    setcurrentquestion(questions?.length > 0 ? questions[currentindex] : null);
  }, [currentindex, questions]);
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row justify-between">
        <h1 className="text-[20px]">Question {currentindex + 1}</h1>
        <div className="flex flex-row gap-3">
          {currentindex > 0 && (
            <button
              onClick={() => setcurrentindex((prev) => prev - 1)}
              className="elementselector"
            >
              Pervious
            </button>
          )}

          {currentindex != questions.length - 1 && (
            <button
              onClick={() => setcurrentindex((prev) => prev + 1)}
              className="elementselector"
            >
              Next
            </button>
          )}
          {}
          {currentquestion?.status == "PENDING" && (
            <button
              onClick={() => {
                submitquestion({
                  uid: currentquestion?.uuid,
                  updateobject: {
                    status: "SKIPPED",
                  },
                });
              }}
              className="elementselector"
            >
              Skip
            </button>
          )}
        </div>
      </div>
      <hr />
      <Question
        question={currentquestion}
        type={type}
        submitquestion={submitquestion}
      />
    </div>
  );
}
function QuickDDX({ isVisible, onClose, data }) {
  const [quickquery, setquickquery] = useState();
  const navigate = useNavigate();

  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content max-h-[80vh]  overflow-y-scroll"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full flex items-center justify-center flex-col  ">
          <BsFillPatchCheckFill size={80} className="text-green-700" />
          <h1 className="font-semibold text-[30px]">Congratulations</h1>
          <h2>
            You have successfully completed this case. Here is your case
            evaluation report
          </h2>
        </div>
        <div className="flex flex-col gap-5">
          {data
            ?.filter((rec) => rec?.evaluation)
            .map((rec) => (
              <div className="flex flex-col">
                <div className="w-full flex flex-row items-center justify-between">
                  <h2 className="">Case Evaluation Report</h2>
                  <div className="clinicalheaderrow">
                    <div className="elementselector">
                      Total Score: {rec?.score}/10
                    </div>
                  </div>
                </div>
                <div className="quickddxbody">
                  {rec?.evaluation?.length > 0 &&
                    rec?.evaluation?.map((evalrec) => (
                      <div className="flex flex-wrap gap-3 items-center">
                        <div className="text-[1em] font-semibold">
                          {evalrec.factor} {evalrec?.score}/2.5:
                        </div>{" "}
                        <p>{evalrec.evaluation}</p>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
        <div className="contentright">
          <button
            className="elementselectorfilled"
            onClick={() => (window.location = "/clinical")}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default function ClinicalMCQs() {
  const [received, setreceived] = useState(false);
  const navigate = useNavigate();
  const [managepatient, setmanagepatient] = useState();
  const [showeval, setshoweval] = useState(false);
  const { uid } = useParams();
  const {
    data: testdata,
    isLoading: testloading,
    isError: tesiserror,
    error: testerror,
  } = useGetRunningTestQuery(uid);
  const {
    data: questiondata,
    isLoading: questionloading,
    isError: questioniserror,
    error: questionerror,
  } = useGetQuestionsQuery(uid, {
    pollingInterval: received ? 0 : 5000,
  });

  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const createdTime = new Date(testdata?.data?.created_at).getTime();
    const durationInMillis = testdata?.data?.duration * 60 * 1000;
    const endTime = createdTime + durationInMillis;

    const checkTime = () => {
      const currentTime = Date.now();

      const remaining = endTime - currentTime;
      if (remaining <= 0) {
        navigate("/clinical");
      } else {
        setTimeRemaining(Math.floor(remaining / 1000));
      }
    };

    const interval = setInterval(checkTime, 1000);

    checkTime();

    return () => clearInterval(interval);
  }, [testdata]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  useEffect(() => {
    if (testerror) {
      navigate("/clinical");
    }
  }, [tesiserror]);

  useEffect(() => {
    if (questiondata && questiondata?.length > 0) {
      setreceived(true);
    }
    if (
      received &&
      questiondata &&
      questiondata?.filter(
        (rec) => rec.status === "ANSWERED" || rec.status === "SKIPPED"
      ).length == questiondata?.length
    ) {
      setshoweval(true);
    }
  }, [questiondata]);

  return (
    <div className="homemain">
      <SideBar
        selected="Clinical"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <QuickDDX
        isVisible={showeval}
        onClose={() => setshoweval(false)}
        data={questiondata}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <HomemainHeader
          onToggle={toggleSidebar}
          isSidebarCollapsed={isSidebarCollapsed}
        />
        <div className="clincalbody">
          <div className="clinicalprogress">
            <h5>Case Progress</h5>
            <div className="clinicalprogressestimate">
              <h4>
                {(questiondata?.filter(
                  (rec) => rec.status === "ANSWERED" || rec.status === "SKIPPED"
                ).length /
                  5) *
                  100}
                % to Complete
              </h4>
              {timeRemaining && (
                <div className="clinicalprogressestimatetime">
                  <img src={TimeIcon} alt="Time" />
                  <p>{timeRemaining} seconds</p>
                </div>
              )}
            </div>
            <div className="clinicalprogresspercentage">
              <div
                style={{
                  width: `${
                    (questiondata?.filter(
                      (rec) =>
                        rec.status === "ANSWERED" || rec.status === "SKIPPED"
                    ).length /
                      5) *
                    100
                  }%`,
                }}
                className="clinicalprogressbar"
              ></div>
            </div>
          </div>
          <div className="clinicalbodyelements">
            {testloading ? (
              <CaseStudySkeletton />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: testdata?.data?.case_studies[0]?.content,
                }}
              />
            )}
            {!managepatient && questiondata?.length > 0 && (
              <button
                onClick={() => setmanagepatient(true)}
                className={`w-full ${
                  !managepatient ? "bg-[#0090FF]" : "bg-gray-300 animate-pulse"
                } text-white text-center p-[6px] rounded-lg mt-4`}
              >
                Manage the Patient
              </button>
            )}
          </div>
          {managepatient && (
            <div className="clinicalbodyelements">
              {questiondata?.length > 0 ? (
                <Questions
                  questions={questiondata}
                  type={testdata?.data?.question_type}
                />
              ) : (
                <QuestSkeleton />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
