import React from "react";
import "../styles/termsandcondition.css";
import { useNavigate } from "react-router-dom";
export default function ContactUs() {
  const navigate = useNavigate();
  return (
    <div className="loginmain">
      <div className="conditionscontentbody">
        <div
          className="conditionsbodyheader"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back To Dashboard
        </div>
        <h1>Contact Us</h1>
        <p class="last-updated">Last updated: 11-09-2024</p>

        <div class="contact-info">
          <p>
            <strong>Merchant Legal Entity Name:</strong> SHIV VIRMANI
          </p>
          <p>
            <strong>Registered Address:</strong> 580 SECTOR 9, URBAN ESTATE,
            GURGAON, Gurugram, Haryana, PIN: 122001
          </p>
          <p>
            <strong>Operational Address:</strong> 580 SECTOR 9, URBAN ESTATE,
            GURGAON, Gurugram, Haryana, PIN: 122001
          </p>
          <p>
            <strong>Telephone No:</strong> 9582131358
          </p>
          <p>
            <strong>Email ID:</strong>{" "}
            <a href="mailto:shivvirmani1313@gmail.com">
              shivvirmani1313@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
