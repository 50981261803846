import { apiSlice } from "../apiSlice";

export const generalSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    solveMyQuery: builder.mutation({
      query: (query) => ({
        url: "api/v1/in-mind/",
        method: "POST",
        body: query,
      }),
    }),
    GetsolveMyQuery: builder.query({
      query: (uid) => ({
        url: `api/v1/get-in-mind/${uid}/`,
        method: "GET",
      }),
      providesTags: ["Query"],
    }),
    GetDiagnosis: builder.mutation({
      query: (formdata) => ({
        url: `api/v1/diagnosis/`,
        method: "POST",
        body: formdata,
      }),
    }),
  }),
});

export const {
  useSolveMyQueryMutation,
  useGetsolveMyQueryQuery,
  useGetDiagnosisMutation,
} = generalSlice;
