import React, { useState } from "react";
import "../styles/quickddx.css";
const clipicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/diagnose/clip.svg";

export default function QuickDDX({ isVisible, onClose, finalstage }) {
  const [quickquery, setquickquery] = useState();
  const [selectedFile, setSelectedFile] = useState(null); // New state for storing selected file

  if (!isVisible) return null;

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file selected
    if (file) {
      setSelectedFile(file); // Set the selected file state
    }
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <h2>Quick DDx</h2>
        <p>
          Enter your case summary and attach investigatory/imaging files below.
        </p>
        <div className="quickddxbody">
          <div className="quickddxbodyheader">
            <button>
              <label htmlFor="upload-photo">
                <img src={clipicon} alt="Profile" className="upload-icon" />
              </label>
              <input
                type="file"
                id="upload-photo"
                accept="image/png, image/jpeg, application/pdf"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </button>
            {selectedFile && (
              <div className="file-name">{selectedFile.name}</div>
            )}
          </div>
          <textarea
            onChange={(e) => {
              setquickquery(e.target.value);
            }}
            placeholder="Example: A 58-year-old male with a history of DM and HTN presents with progressive muscle weakness, stroke-like episodes, and bilateral hearing loss..."
          ></textarea>
        </div>
        <div className="contentright">
          <button className="elementselector" onClick={onClose}>
            Cancel
          </button>
          <button
            className="elementselectorfilled"
            onClick={() => finalstage(quickquery)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
