import React from "react";
import "../styles/loungequestion.css";
const arrowdownicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/arrowdown.svg";
const crossicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/crossicon.svg";
const speakerimg =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/questionspeaker.svg";
const publicicon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/lounge/questionpublic.svg";

export default function LoungeQuestion({ isVisible, onClose }) {
  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content-question"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="loungeaskheader">
          <div className="loungeaskleft">
            <img src={crossicon} alt="Cross" onClick={onClose} />
          </div>
          <div className="loungeaskright">
            <h4>Add question</h4>
            <img src={arrowdownicon} alt="Arrow Down" />
          </div>
        </div>

        <div className="loungeaskbody">
          <div className="loungeaskspeaker">
            <img
              src={speakerimg}
              alt="Speaker"
              className="imageround loungeaskspeakerimg"
            />
            <p>Pete Lada asked </p>
            <div className="questionaudience">
              <img src={publicicon} alt="Public" />
              <p>Public</p>
              <hr />
              <img src={arrowdownicon} alt="Arrow Down" />
            </div>
          </div>
          <textarea placeholder="Start your question with “What”, “How”, “Why”, etc."></textarea>
        </div>
        <hr />
        <div className="contentright">
          <button className="questioncancel" onClick={onClose}>
            Cancel
          </button>
          <button className="questionsubmit" onClick={onClose}>
            Add Question
          </button>
        </div>
      </div>
    </div>
  );
}
