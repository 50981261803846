import React from "react";
import { useGetUserScoreQuery } from "../store/caseSimulation/CaseStudyApiSlice";

const headerbadge =
  "https://neuro-gen.s3.amazonaws.com/images/icons/headerbadge.svg";
const fireicon = "https://neuro-gen.s3.amazonaws.com/images/icons/fire.svg";
const reloadicon = "https://neuro-gen.s3.amazonaws.com/images/icons/reload.svg";
const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";

export default function HomemainHeader({ onToggle, isSidebarCollapsed }) {
  const { data, isLoading } = useGetUserScoreQuery();
  return (
    <div className="homemainheader">
      <div className="homemainleft">
        <div className="homemainleftinner">
          <div className="closesidebarbutton" onClick={onToggle}>
            <img src={isSidebarCollapsed ? OpenIcon : CloseIcon} alt="Arrow" />
          </div>
        </div>
        <div className="homemainleftouter">
          <h3>Hello Dr.{data?.name},</h3>
          <p>Your patient needs you, Let's rush to the rescue</p>
        </div>
      </div>
      <div className="homemainright">
        <button>
          <div className="homemainrightbuttoncontent">
            <img src={headerbadge} alt="Header Badge" />
            <b>'Novice'</b> Badge
          </div>
          <div className="headerstreaks">
            <img src={fireicon} alt="Fire" />
            <p>{data?.score || 0}</p>
          </div>
        </button>
        <button>
          <img src={reloadicon} alt="Reload" />
        </button>
      </div>
    </div>
  );
}
