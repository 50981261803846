import { apiSlice } from "../apiSlice";

export const caseStudySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkRunningTest: builder.query({
      query: () => ({
        url: "api/v1/check-running-casestudy/",
        method: "GET",
      }),
      providesTags: ["CaseStudy"],
    }),
    getRunningTest: builder.query({
      query: (uid) => ({
        url: `api/v1/get-running-casestudy/${uid}/`,
        method: "GET",
      }),
      providesTags: ["CaseStudy"],
    }),
    createCaseStudy: builder.mutation({
      query: (casedata) => ({
        url: "api/v1/create-casestudy-master/",
        method: "POST",
        body: casedata,
      }),
      // invalidatesTags: ["CaseStudy"],
    }),
    getQuestions: builder.query({
      query: (uid) => ({
        url: `api/v1/getquestion/${uid}/`,
        method: "GET",
      }),
      providesTags: ["Questions"],
    }),
    getAllCaseStudy: builder.query({
      query: () => ({
        url: "api/v1/get-my-casestudy/",
        method: "GET",
      }),
      providesTags: ["CaseStudy"],
    }),
    getUserScore: builder.query({
      query: () => ({
        url: "api/v1/get-total-score/",
        method: "GET",
      }),
      providesTags: ["Score"],
    }),
    submitQuestion: builder.mutation({
      query: ({ uid, updateobject }) => ({
        url: `api/v1/update-question/${uid}/`,
        method: "PUT",
        body: updateobject,
      }),
      invalidatesTags: ["Score", "Questions"],
    }),
  }),
});

export const {
  useCheckRunningTestQuery,
  useGetRunningTestQuery,
  useCreateCaseStudyMutation,
  useGetQuestionsQuery,
  useGetAllCaseStudyQuery,
  useGetUserScoreQuery,
  useSubmitQuestionMutation,
} = caseStudySlice;
