import React from "react";

import { useNavigate } from "react-router-dom";
const congratsimage =
  "https://neuro-gen.s3.amazonaws.com/images/icons/congratulationstick.svg";

export default function ClinicalCongratsLearning({ isVisible, onClose }) {
  const navigate = useNavigate();
  const handleNextCase = () => {
    onClose();
    navigate("/clinicalcase/mcq");
  };
  const handleHome = () => {
    onClose();
    navigate("/home");
  };

  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div
        className="popup-content-congrats popup-content-congrats-tick"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={congratsimage} alt="Congrats" className="congratstick" />
        <h1>Congratulations</h1>
        <p className="textcenter">
          You have successfully completed this case. Here is your case
          evaluation report
        </p>

        <div className="clinicalelementheader">
          <div className="clinicalimageheader">
            <h4>Learning Insights</h4>
          </div>
          <div className="clinicalheaderrow">
            <div className="elementselector">Total Score: 36/40</div>
          </div>
        </div>
        <hr />
        <div className="clinicalelementbodyevaluation">
          <div className="textareabox">
            <strong>Overall Score: 36/40</strong>
            <ul>
              <li>
                <strong>Leadership: 8/10 : </strong>
                The student demonstrates a proactive approach with a clear,
                structured plan. Slightly more emphasis on team coordination
                could enhance leadership.
              </li>
              <li>
                <strong>Clinical Knowledge: 9:10 : </strong>
                Strong understanding of aortic dissection, diagnostic
                strategies, and management. Mentioning differential diagnoses
                could show even broader knowledge.
              </li>
              <li>
                <strong>Emotional Intelligence: 8/10 : </strong>
                The response shows concern for patient comfort with pain
                management. Acknowledging patient and family communication could
                further elevate emotional intelligence.
              </li>
              <li>
                <strong>Decision Making: 9/10 : </strong>
                Logical, evidence-based decisions are made, prioritizing the
                patient’s immediate needs. Briefly considering potential
                complications would strengthen this further.
              </li>
            </ul>
          </div>
        </div>

        <div className="contentcenter">
          <button className="elementselector" onClick={handleHome}>
            Home
          </button>
          <button className="elementselectorfilled">Download</button>
          <button className="elementselectorfilled" onClick={handleNextCase}>
            Next Case
          </button>
        </div>
      </div>
    </div>
  );
}
