import React, { useState } from "react";
import SideBar from "../elements/sidebar";
import "../styles/research.css";
const expertadviceimages =
  "https://neuro-gen.s3.amazonaws.com/images/icons/expertadvice.svg";
const UploadIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/research/upload.svg";

const OpenIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/open.svg";
const CloseIcon =
  "https://neuro-gen.s3.amazonaws.com/images/icons/sidebar/close.svg";

export default function ExpertAdvice() {
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSumbmit = (e) => {
    e.preventDefault();
  };

  const handleOptionChange = (event) => {
    if (!isSubmitted) {
      setSelectedOption(event.target.value);
    }
  };
  const [fileName, setFileName] = useState("Upload Here");
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    }
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    window.innerWidth < 1000 ? true : false
  );
  return (
    <div className="homemain">
      <SideBar
        selected="Expert"
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
      />
      <div
        className="homeright"
        style={{
          marginLeft: isSidebarCollapsed ? "110px" : "370px",
        }}
      >
        <div className="researchheader">
          <div className="homemainleftinner">
            <div className="closesidebarbutton" onClick={toggleSidebar}>
              <img
                src={isSidebarCollapsed ? OpenIcon : CloseIcon}
                alt="Arrow"
              />
            </div>
          </div>
          <div className="homemainleftouter">
            <h3>Consult Our Expert Medical Team:</h3>
            <p>Confused? We have got you covered, </p>
          </div>
        </div>
        <div className="researchbody">
          <div className="researchelement">
            <div className="researchelementleft">
              <form onSubmit={handleSumbmit}>
                <h5>Ask your Query :</h5>
                <input
                  type="text"
                  placeholder="Hey, I’m stuck & need help with my eisenmenger case!"
                  required
                />
                <h5>Describe your Case / Query for tailored advice :</h5>
                <textarea placeholder="Critical! Our 17-year-old patient from Rajasthan suffers from worsening breathlessness, syncope, and pink frothy cough. Severe anemia, Pan-Systolic Murmur, and DORV with VSD found on echo. Vitals are alarming. Seeking urgent advice on management!"></textarea>
                <div className="clinicalbodyoptions expertoptions">
                  <h5>Urgeny Level</h5>

                  <div className="clinicalbodyoption ">
                    <input
                      type="radio"
                      name="clinicalOption"
                      id="option1"
                      value="1"
                      checked={selectedOption === "1"}
                      onChange={handleOptionChange}
                      disabled={isSubmitted}
                      required
                    />
                    <label htmlFor="option1">Emergency (ASAP)</label>
                  </div>
                  <div className="clinicalbodyoption">
                    <input
                      type="radio"
                      name="clinicalOption"
                      id="option2"
                      value="2"
                      checked={selectedOption === "2"}
                      onChange={handleOptionChange}
                      disabled={isSubmitted}
                    />
                    <label htmlFor="option2">
                      High Priority (Within 24 hrs)
                    </label>
                  </div>
                  <div className="clinicalbodyoption">
                    <input
                      type="radio"
                      name="clinicalOption"
                      id="option3"
                      value="3"
                      checked={selectedOption === "3"}
                      onChange={handleOptionChange}
                      disabled={isSubmitted}
                    />
                    <label htmlFor="option3">Standard (24 to 48 hrs)</label>
                  </div>
                </div>
                <h5>Add Lab & Imaging Files</h5>
                <p>Image Upload/ PDF Upload</p>
                <div className="fileupload">
                  <input
                    type="file"
                    id="file"
                    accept=".pdf, image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />

                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    {fileName}
                    {!file && <img src={UploadIcon} alt="Upload" />}
                  </label>
                </div>

                <button type="submit" className="generatebutton">
                  Submit
                </button>
              </form>
            </div>
            <div className="researchelementright">
              <img src={expertadviceimages} alt="Article" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
