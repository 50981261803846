import React from "react";
import "../styles/termsandcondition.css";
import { useNavigate } from "react-router-dom";
export default function TandC() {
  const navigate = useNavigate();
  return (
    <div className="loginmain">
      <div className="conditionscontentbody">
        <div
          className="conditionsbodyheader"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back To Dashboard
        </div>
        <h1>Terms & Conditions</h1>
        <p class="last-updated">Last updated: 11/09/2024</p>
        <h1>NeuroGEN - TERMS AND CONDITIONS OF USE</h1>
        <p>
          Welcome to NeuroGEN, an artificial intelligence-powered medical
          education website located at{" "}
          <a href="http://www.neurogenn.com">www.neurogenn.com</a> (The
          “Website”).
        </p>
        <p>
          This site is owned and operated by SHIV VIRMANI (The Merchant legal
          entity name). By using our website, you agree to the following terms
          and conditions (The “Terms”). If you do not agree to the Terms,
          policies, and conditions of these Terms, any additional documents
          including the Privacy Policy (Section “Privacy”), you may not use the
          Website. Shiv Virmani reserves the right to modify these Terms at any
          time. It is your responsibility to review the Terms before each use of
          the Website.
        </p>

        <h2>1) Purpose of the Website:</h2>
        <p>
          Our website is for educational purposes only and is not intended to
          provide medical advice or patient care. Any information provided on
          the website is for informational purposes only and should not be used
          as a substitute for professional medical advice, diagnosis, or
          treatment. The website is intended for people in medical training.
        </p>
        <ul>
          <li>
            <strong>a) Healthcare professionals:</strong> The Website is in no
            way intended to serve as a diagnostic service or platform, to
            provide certainty with respect to a diagnosis, to recommend a
            particular product or therapy or to otherwise substitute for the
            clinical judgment of a qualified healthcare professional. You agree
            that you will not use the Website with the intention of creating any
            kind of physician/patient relationship, e.g., to diagnose or treat.
            You are solely responsible for evaluating the information obtained
            from the Website and for your use or misuse of such information in
            connection with your treatment decisions or otherwise. You agree
            that you shall be solely responsible for your compliance with all
            laws and standards of professional practice applicable to you and
            the practice of medicine or other relevant health profession.
          </li>
          <li>
            <strong>b) Medical Students:</strong> The Websites provided by Shiv
            Virmani should be used only as a tool to assist you in preparing for
            licensing exams and for no other purpose. The Websites are not
            intended to replace the traditional classroom education experience
            and are solely intended as a resource to add to your education. Shiv
            Virmani makes no representations or warranties of any kind
            whatsoever regarding the outcome of any exams that you are preparing
            to take or may take after using the Websites provided by Shiv
            Virmani. Shiv Virmani is in no way affiliated with the AIIMS, the
            NBEMS, the USMLE, or any other governing licensing body.
          </li>
          <li>
            <strong>c) Other Consumers:</strong> If you are a consumer who
            chooses to access the information made available through the
            Website, you should not rely on that information as professional
            medical advice or use the Website as a replacement for any
            relationship with your physician or other qualified healthcare
            professional. For medical concerns, including decisions about
            medications and other treatments, consumers should always consult
            their physician or, in serious cases, seek immediate assistance from
            emergency medical personnel. The Website and the Websites do not
            constitute the practice of any medical, nursing or other
            professional healthcare advice, diagnosis or treatment.
          </li>
        </ul>

        <h2>2) Registration and Access:</h2>
        <p>
          You must be at least 13 years old to use the Services. If you are
          under 18 you must have your parent or legal guardian’s permission to
          use the Services. If you use the Services on behalf of another person
          or entity, you must have the authority to accept the Terms on their
          behalf.
        </p>

        <h2>3) License and Subscriptions</h2>
        <ul>
          <li>
            <strong>a) License:</strong> In consideration of and subject to your
            payment of the appropriate subscription fee for the site features
            you subscribe to, and your agreement to and compliance with the
            terms, policies and conditions set forth in these Terms, Neuroverse
            Healthcare grants to you a limited, revocable, non-exclusive,
            non-sublicensable, non-transferable license to use and access the
            content and features posted at the Website according to the terms,
            policies and conditions set forth in these Terms.
          </li>
          <li>
            <strong>b) Term:</strong> The Term of these Terms and the license
            granted in these Terms shall be concurrent with the term of your
            subscription to the Website (“Term”) and may be terminated earlier
            than the expiration of your subscription as provided below.
          </li>
          <li>
            <strong>c) Access:</strong> You may access the Website during the
            Term from multiple places, locations, and computers, but you are not
            allowed to access the Website from more than one place, location, or
            computer at the same time. You are responsible for making all
            arrangements necessary for you to have access to the Website and
            ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Use and comply with
            them.
          </li>
          <li>
            <strong>d) Subscription Tiers:</strong> The Company offers two tiers
            of pricing for a license to use the Website.
            <ul>
              <li>
                <strong>i) Free tier:</strong> Eligible users can create an
                account for free that allows the following access to the
                Website: Initial 7 days free Trial with access to every feature,
                but 1 use per day of all other Website features and tools. This
                pricing and Term is subject to change without notice. Void where
                prohibited, taxed, or restricted by law.
              </li>
              <li>
                <strong>ii) Paid tier:</strong> Eligible users can purchase a
                paid subscription for access to all of the site's published
                tools and features for Rs 599/month or Rs 5999/year. At the end
                of the Subscription Term as outlined in section 3(g), you will
                be billed automatically unless your subscription is cancelled.
                Cancellation is available any time outlined in section 3(h).
                Access may be limited if judged as abusive (including but not
                limited to, using the site beyond any reasonable amount of
                studying would require) or violates these Terms in any way as
                judged by the sole discretion of the Company. This pricing is
                subject to change in accordance with section 3(e). Void where
                prohibited, taxed, or restricted by law.
              </li>
            </ul>
          </li>
          <li>
            <strong>e) Fee Increase:</strong> The Company reserves the right to
            increase fees for any services, upon thirty (30) days’ prior written
            notice, effective on the start date of your subsequent subscription
            term.
          </li>
          <li>
            <strong>f) Member Account/Password and Security:</strong> You must
            complete the Website’s registration process in order to subscribe to
            and use the Website by providing us with current, complete, and
            accurate information as prompted by the registration form. You also
            will choose a password and a Username. You are entirely responsible
            for maintaining the confidentiality of your password and account.
            Furthermore, you are entirely responsible for any and all activities
            that occur under your account. You agree to notify the Company
            immediately of any unauthorized use of your account or any other
            breach of security. The Company will not be liable for any loss that
            you may incur as a result of someone else using your password or
            account, either with or without your knowledge. Furthermore, you
            could be held liable for losses incurred by the Company or another
            party due to someone else using your account or password.
          </li>
          <li>
            <strong>g) Initiation of contract/Subscription Term:</strong> With
            any use of the site, you are bound by these Terms. However,
            regarding any paid subscriptions, you have the option to submit a
            binding offer for the initiation of a contract by clicking the
            button “Purchase” after completing the online order process. After
            submission of the offer, the Company will send an order confirmation
            to you by E-Mail. The order confirmation constitutes the acceptance
            of your offer by the Company. The license granted to you under these
            Terms includes the right to use and access the Website in accordance
            with the access Tier as outlined in section 3(d) you have subscribed
            to for the subscription period you initially choose. At the end of
            this period, your right to use and access the Website and your
            subscription will renew automatically, unless you cancel your
            subscription under the terms, policies and conditions then current.
            These Terms and your license to use the Website and its features are
            also subject to termination by the Company, at its sole and absolute
            discretion, if you fail to comply with any term, policy, or
            condition in these Terms.
          </li>
          <li>
            <strong>h) Cancellation/refund policy:</strong> You may cancel a
            paid subscription at any point. This may be completed on the Website
            or by contacting the Company at{" "}
            <a href="mailto:contactus@neuralconsult.com">
              contactus@neuralconsult.com
            </a>
            . You will be given access to the Website’s paid services and
            features purchased in accordance with your Tier for the remainder of
            the Term in which you paid. No refunds will be granted for
            cancellations. You will be automatically switched to the free tier,
            unless you opt to delete your account entirely in which your data
            will be handled in accordance with the Privacy Policy.
          </li>
        </ul>
        <h2>4) Usage</h2>
        <ul>
          <li>
            <strong>a) Use of Services:</strong> You may access, and we grant
            you a non-exclusive right to use, the Website in accordance with
            these Terms and License in section 3. You will comply with these
            Terms and all applicable laws when using the Website. We and our
            affiliates own all rights, title, and interest in and to the Website
            and its features.
          </li>
          <li>
            <strong>b) Feedback:</strong> We appreciate feedback, comments,
            ideas, proposals, and suggestions for improvements. If you provide
            any of these things, we may use it without restriction or
            compensation to you.
          </li>
          <li>
            <strong>c) Restrictions:</strong> You may not:
            <ul>
              <li>
                (i) use the Website in a way that infringes, misappropriates or
                violates any person’s rights;
              </li>
              <li>
                (ii) reverse assemble, reverse compile, decompile, translate or
                otherwise attempt to discover the source code or underlying
                components of models, algorithms, and systems of the Website
                (except to the extent such restrictions are contrary to
                applicable law);
              </li>
              <li>
                (iii) use output from the Website to develop any service or
                product that competes with Dendritic Health;
              </li>
              <li>
                (iv) use any automated or programmatic method to extract data or
                output from the Website, including scraping, web harvesting, or
                web data extraction;
              </li>
              <li>
                (v) represent that output from the Website was human-generated
                when it is not;
              </li>
              <li>
                (vi) buy, sell, or transfer links from our Website without our
                prior consent.
              </li>
            </ul>
          </li>
        </ul>

        <h2>5) Content</h2>
        <p>
          NeuroGEN utilizes OpenAI, L.L.C’s (“OpenAI”) API. OpenAI’s terms and
          conditions can be found at{" "}
          <a href="https://openai.com/policies/terms-of-use">
            https://openai.com/policies/terms-of-use
          </a>{" "}
          and apply to usage of the Website. If or when OpenAI’s and our
          policies conflict, Terms written in this document apply.
        </p>
        <ul>
          <li>
            <strong>a) Your Content:</strong> You may provide input to the
            Website (“Input”), and receive output generated and returned by the
            Website based on the Input (“Output”). Input and Output are
            collectively “Content.” As between the parties and to the extent
            permitted by applicable law, you own all Input. Subject to your
            compliance with these Terms, Neuroverse Healthcare hereby assigns to
            you all its right, title, and interest in and to Output. This means
            you can use Content for any purpose, including commercial purposes
            such as sale or publication, if you comply with these Terms. The
            Company may use Output data to enhance its Website feature
            performance. You are responsible for Content, including to ensure
            that it does not violate any applicable law or these Terms. This
            includes, but is not limited to:
            <ul>
              <li>
                (i) HIPAA-protected health information (“PHI”). PHI should never
                be entered as Input.
              </li>
              <li>
                (ii) Copyright material laws. Copyright material should not be
                entered as Input.
              </li>
              <li>
                (iii) Your educational institution or employer’s policies. It is
                your responsibility to not enter Input or use Output in a way
                that violates your institution’s or employer’s policies.
              </li>
            </ul>
          </li>
          <li>
            <strong>b) Output Disclaimer:</strong> Neuroverse Healthcare cannot
            guarantee that information provided as Output on the website is
            accurate, correct, or medically legitimate. It is your
            responsibility to verify Output provided with other sources to
            confirm accuracy.
          </li>
        </ul>

        <h2>6) User Conduct</h2>
        <p>
          You agree that you will not use the website to upload, post, or
          transmit any Content that is infringing, defamatory, or otherwise
          unlawful. You are solely responsible for ensuring that any content you
          upload to the website is not copyrighted, HIPAA-protected, in
          violation of your employer or educational institution’s policies or
          otherwise restricted by law. Refer to Section 4 and 2. You agree to
          indemnify and hold us harmless from any claims arising from your use
          of the website.
        </p>

        <h2>7) Intellectual Property</h2>
        <p>
          All content other than Input and Output as defined in section 5(a) on
          the website, including but not limited to text, graphics, images,
          videos, and software, is owned by us or our licensors and is protected
          by copyright and other intellectual property laws. You may not use,
          reproduce, or distribute any content from the Website except as
          outlined in Section 5(a) without our prior written consent.
        </p>

        <h2>8) Privacy</h2>
        <p>
          We take your privacy seriously and will use your personal information
          in accordance with our Privacy Policy. You agree that we may collect,
          use, and disclose your personal information as described in our
          Privacy Policy which can be found at{" "}
          <a href="https://neurogenn.com">neurogenn.com</a>.
        </p>
        <h2>
          9) Indemnification; Disclaimer of Warranties; Limitations on Liability
        </h2>
        <ul>
          <li>
            <strong>a) Indemnity:</strong> You will defend, indemnify, and hold
            harmless us, our affiliates, and our personnel, from and against any
            claims, losses, and expenses (including attorneys’ fees) arising
            from or relating to your use of the Website, including your Content,
            products or services you develop or offer in connection with the
            Website, and your breach of these Terms or violation of applicable
            law.
          </li>
          <li>
            <strong>b) Disclaimer:</strong> THE SERVICES ARE PROVIDED “AS IS.”
            EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND
            LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR
            OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL WARRANTIES
            INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT,
            AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF
            DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL BE
            UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE
            SECURE OR NOT LOST OR ALTERED.
          </li>
          <li>
            <strong>c) Limitations of Liability:</strong> NEITHER WE NOR ANY OF
            OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES,
            EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR
            AGGREGATE LIABILITY UNDER THESE TERMS SHALL NOT EXCEED THE GREATER
            OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM
            DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED
            DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </li>
        </ul>

        <h2>10) Governing Law</h2>
        <p>
          These terms and conditions shall be governed by and construed in
          accordance with the Govt. of India. Any disputes arising from your use
          of the website shall be resolved in accordance with these laws.
        </p>

        <h2>11) Changes to Terms and Conditions</h2>
        <p>
          We may update these terms and conditions from time to time without
          notice. Your continued use of the website after any such changes
          constitutes your acceptance of the revised terms and conditions.
        </p>

        <h2>12) Violation</h2>
        <p>
          In the event that you breach any of the terms, policies, and
          conditions of these Terms, SHIV VIRMANI may, in its sole and absolute
          discretion, terminate these Terms, your access to the Website, and its
          provision of Resources to you without refund. SHIV VIRMANI reserves
          the right to seek all remedies available by law and in equity for such
          breaches.
        </p>

        <h2>13) Contact</h2>
        <p>
          If you have any questions about these terms and conditions, please
          contact us at{" "}
          <a href="mailto:Neuroverse.healthcare@gmail.com">
            Neuroverse.healthcare@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
