import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { useCheckProfileCompleteQuery } from "../store/profile/UserApiSlice";
const ProtectedRoutes = () => {
  const token = Cookies.get("token");
  const { data, isLoading } = useCheckProfileCompleteQuery();
  if (!token) {
    return <Navigate to="/" />;
  } else if (token && !data && !isLoading) {
    return <Navigate to="/register/about" />;
  }
  return <Outlet />;
};

export default ProtectedRoutes;
