import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./index.css";

import ClinicalHome from "./pages/clinicalhome";
import ClinicalMCQs from "./pages/clinicalmcq";
import ClinicalDetailed from "./pages/clinicaldetailed";
import Dashboard from "./pages/dashboard";
import AiSearch from "./pages/aisearch";
import ResearchLiterature from "./pages/researchliterature";
import ResearchArticle from "./pages/researcharticle";
import ResearchReport from "./pages/researchreport";
import Login from "./pages/login";
import RegistrationPage from "./pages/registration";
import ExpertAdvice from "./pages/expertadvice";
import Profile from "./pages/profile";
import Diagnose from "./pages/diagnose";
import ProtectedRoutes from "./components/ProtectedRoute";
import Lounge from "./pages/lounge";
import CaseListing from "./pages/cases";
import ImageDx from "./pages/imagedx";
import Investigation from "./pages/investigation";
import Symptom from "./pages/symptom";
import TandC from "./pages/termsandcondition";
import PrivacyPolicy from "./pages/privacypolicy";
import ContactUs from "./pages/contact-us";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register/:step" element={<RegistrationPage />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/aisearch/:uid" element={<AiSearch />} />
            <Route path="/clinical" element={<ClinicalHome />} />
            <Route path="/clinicalcase/mcq" element={<ClinicalMCQs />} />
            <Route
              path="/clinicalcase/questions/:uid"
              element={<ClinicalMCQs />}
            />

            <Route
              path="/clinicalcase/detailed"
              element={<ClinicalDetailed />}
            />
            <Route
              path="/research-literature"
              element={<ResearchLiterature />}
            />
            <Route path="/research-article" element={<ResearchArticle />} />
            <Route path="/research-report" element={<ResearchReport />} />
            <Route path="/expert" element={<ExpertAdvice />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/diagnose" element={<Diagnose />} />
            <Route path="/lounge" element={<Lounge />} />
            <Route path="/cases" element={<CaseListing />} />
            <Route path="/imagedx" element={<ImageDx />} />
            <Route path="/investigatory" element={<Investigation />} />
            <Route path="/symptom" element={<Symptom />} />
            <Route path="/terms" element={<TandC />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
